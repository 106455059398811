import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

const ProductActions = ({ productId, onDelete }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/admin/edit-parent-product/${productId}`);
  };

  return (
    <div className="product-actions card">
      <h3>Actions</h3>
      <div className="action-buttons">
        <button onClick={handleEdit} className="btn-edit">
          Edit Product
        </button>
        <Link 
          to={`/admin/inventory-logs/${productId}`} 
          className="btn-logs"
        >
          View Inventory Logs
        </Link>
        <button onClick={onDelete} className="btn-delete">
          Delete Product
        </button>
      </div>
    </div>
  );
};

export default ProductActions; 