import React from "react";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { styled } from '@mui/system';
import { useToast } from '../../context/ToastContext';


const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 'none',
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        fontSize: '12px',
        padding: '4px 8px',
    },
    '& .MuiDataGrid-columnHeaders': {
        color: 'white',
        fontWeight: '500',
        fontSize: '14px',
        // padding: '8px 12px',
    },
    '& .super-app-theme--header': {
        backgroundColor: '#33387C',
    },
    '& .MuiDataGrid-footerContainer': {
        display: 'none',
    },
    "& .MuiDataGrid-cell--editable": {
        backgroundColor: "#ffffff !important",
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
    },
    '& .disabled-cell': {
        backgroundColor: '#f0f0f0',
        color:' #999',
        pointerEvents: 'none',
    },
    '& .MuiDataGrid-main': {
        border: '1px solid #ccc',
    }
}));

const EditableTable = ({ rows, columns, setSkuRows, hideFooter, loading, setSelectedQuantities}) => {
    const [cellModesModel, setCellModesModel] = React.useState({});
    const toast = useToast();
    const processRowUpdate = (newRow, oldRow) => {
        for (const key in newRow) {
            if (newRow[key] < 0) {
                newRow[key] = 0; // Replace negative numbers with 0
            }
            if (newRow[key] !== oldRow[key]) {
                if(newRow[`${key.slice(4)}`].stock < newRow[key]){
                    newRow[key] = newRow[`${key.slice(4)}`].stock;
                    toast.error(`Only ${newRow[`${key.slice(4)}`].stock} items are available for this SKU.`);
                    setSkuRows((prevRows) =>
                        prevRows.map((row) =>
                            row.id === newRow.id ? { ...newRow } : row
                        )
                    );
                    setSelectedQuantities((prevState) => ({
                        ...prevState,
                        [newRow[`${key.slice(4)}`]?.id]: newRow[key],
                    }));
    
                    return newRow;
                }
                setSelectedQuantities((prevState) => ({
                    ...prevState,
                    [newRow[`${key.slice(4)}`]?.id]: newRow[key],
                }));
            }
        }
        setSkuRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? { ...newRow } : row))
        );
        return newRow;
    };

    const handleCellClick = React.useCallback((params, event) => {
        if (!params.isEditable) {
          return;
        }
    
        // Ignore portal
        if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
          return;
        }
    
        setCellModesModel((prevModel) => {
          return {
            // Revert the mode of the other cells from other rows
            ...Object.keys(prevModel).reduce(
              (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                  (acc2, field) => ({
                    ...acc2,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {},
                ),
              }),
              {},
            ),
            [params.id]: {
              // Revert the mode of other cells in the same row
              ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                {},
              ),
              [params.field]: { mode: GridCellModes.Edit },
            },
          };
        });
      }, []);
    
      const handleCellModesModelChange = React.useCallback((newModel) => {
        setCellModesModel(newModel);
      }, []);

  return (
    <CustomDataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="cell"
        isCellEditable={(params) => Number(params.row[`stock$${params.field.slice(4)}`]) !== 0}
        hideFooter={true}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount={true}
        hideFooterPagination={true}
        getCellClassName={(params) =>
            Number(params.row[`stock$${params.field.slice(4)}`]) === 0 ? "disabled-cell" : "" // Add a class for styling disabled cells
        }
        loading={loading}
        autoHeight={true}
        disableExtendRowFullWidth={true}
        sx={{
            '& .MuiDataGrid-virtualScroller': {
                marginBottom: '0!important'
            },
            '& .MuiDataGrid-row:last-child': {
                borderBottom: 'none'
            },
            '& .MuiDataGrid-footerContainer': {
                display: 'none'
            },
            '& .MuiDataGrid-main': {
                borderBottom: 'none'
            }
        }}
        // onCellClick={handleCellClick}
        // onCellModesModelChange={handleCellModesModelChange}
        // cellModesModel={cellModesModel}
    />
  );
};

export default EditableTable;
