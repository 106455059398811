import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../store/slices/cartSlice';
import { db } from '../firebase-config';
import { collection, addDoc, doc, updateDoc, getDoc, getDocs, query, where } from 'firebase/firestore';
import './PlaceOrder.css';
import { INDIAN_STATES } from '../constants/states';
import ProductSelection from './components/OrderFlow/ProductSelection';
import Stepper from './components/OrderFlow/Stepper';
import useAdminRole from '../hooks/useAdminRole';
import { logInventoryChange } from '../utils/inventoryUtils';
import OrderLoader from '../components/OrderLoader/OrderLoader';
import { calculateItemPrice, calculateCartTotals } from '../utils/priceUtils';

const PlaceOrder = () => {
  const navigate = useNavigate();
  const { cartItems, totalPrice } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    pinCode: '',
    state: '',
    gstNumber: '',
  });
  const [loading, setLoading] = useState(false);
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const steps = ['Select Products', 'Customer Details', 'Review & Place Order'];
  const [currentStep, setCurrentStep] = useState(0);
  const { role } = useAdminRole();
  const [isSearching, setIsSearching] = useState(false);
  const [orderStep, setOrderStep] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'pinCode') {
      setIsPinCodeValid(validatePinCode(value));
    }
    
    setCustomerDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!role || (role !== 'admin' && role !== 'staff')) {
      alert('You must be logged in as admin or staff to place an order');
      return;
    }
    
    setLoading(true);
    setOrderStep(0);

    try {
      setOrderStep(1);
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('mobileNumber', '==', customerDetails.phone));
      const userSnapshot = await getDocs(q);

      let userId;
      if (userSnapshot.empty) {
        const newUser = await addDoc(usersRef, {
          billingName: customerDetails.name,
          mobileNumber: customerDetails.phone,
          email: customerDetails.email,
          address: customerDetails.address,
          courierAddress: null,
          shippingMethod: 'normal',
          createdAt: new Date()
        });
        userId = newUser.id;
      } else {
        userId = userSnapshot.docs[0].id;
      }

      setOrderStep(2);
      const orderRef = await addDoc(collection(db, 'orders'), {
        userId,
        items: cartItems,
        totalAmount: totalPrice,
        status: 'Pending',
        paymentStatus: 'Cash',
        placedBy: role,
        orderType: 'admin',
        orderDate: new Date(),
        shipping: {
          address: customerDetails.address,
          phone: customerDetails.phone,
          billingName: customerDetails.name,
          pinCode: customerDetails.pinCode,
          state: customerDetails.state,
          gstNumber: customerDetails.gstNumber
        }
      });

      const updatedBy = role === 'admin' ? 'admin' : role === 'staff' ? 'staff' : 'unknown';

      for (const item of cartItems) {
        const productRef = doc(db, 'parent_products', item.id);
        const productDoc = await getDoc(productRef);
        const product = productDoc.data();

        const updatedSkus = await Promise.all(product.skus.map(async sku => {
          const selection = item.selections.find(s => s.skuId === sku.skuId);
          if (selection) {
            await logInventoryChange({
              productId: item.id,
              productName: product.name,
              skuId: sku.skuId,
              previousStock: sku.stock,
              newStock: sku.stock - selection.quantity,
              type: 'order_placed',
              updatedBy,
              referenceId: orderRef.id
            });

            return {
              ...sku,
              stock: sku.stock - selection.quantity
            };
          }
          return sku;
        }));

        await updateDoc(productRef, { skus: updatedSkus });
      }

      setOrderSuccess(true);
      setTimeout(() => {
        dispatch(clearCart());
        navigate('/admin/manage-orders');
      }, 2000);
      
    } catch (error) {
      console.error('Error placing order:', error);
      alert('Failed to place order. Please try again.');
      setOrderStep(null);
    } finally {
      setLoading(false);
    }
  };

  const validatePinCode = (pin) => {
    const pinCodeRegex = /^[1-9][0-9]{5}$/;
    return pinCodeRegex.test(pin);
  };

  const validateCustomerDetails = () => {
    if (!customerDetails.name || !customerDetails.phone || !customerDetails.email || 
        !customerDetails.address || !customerDetails.pinCode || !customerDetails.state) {
      alert('Please fill in all customer details');
      return false;
    }

    if (!isPinCodeValid) {
      alert('Please enter a valid PIN code');
      return false;
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(customerDetails.phone)) {
      alert('Please enter a valid 10-digit phone number');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(customerDetails.email)) {
      alert('Please enter a valid email address');
      return false;
    }

    return true;
  };

  const handleNext = () => {
    if (currentStep === 1 && !validateCustomerDetails()) return;
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const searchCustomer = async (phone) => {
    if (phone.length !== 10) return;
    
    setIsSearching(true);
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('mobileNumber', '==', phone));
      const userSnapshot = await getDocs(q);

      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data();
        setCustomerDetails(prev => ({
          ...prev,
          name: userData.billingName || '',
          email: userData.email || '',
          address: userData.address || '',
          phone: userData.mobileNumber || phone,
          state: userData.state || '',
          pinCode: userData.pinCode || '',
          gstNumber: userData.gstNumber || '',
          courierAddress: userData.courierAddress || '',
          shippingMethod: userData.shippingMethod || ''
        }));

        alert('Customer details found and populated!');
        return true;
      } else {
        alert('No customer found with this phone number. Please enter details manually.');
        return false;
      }
    } catch (error) {
      console.error('Error searching customer:', error);
      alert('Error searching for customer. Please try again.');
      return false;
    } finally {
      setIsSearching(false);
    }
  };

  const ReviewStep = () => {
    const { subtotal, totalGst, grandTotal, totalQuantity } = calculateCartTotals(cartItems);

    return (
      <div className="review-step">
        <div className="order-summary">
          <h3>Order Summary</h3>
          <div className="cart-items">
            {cartItems.map((item, index) => (
              <div key={index} className="cart-item">
                <h4>{item.name}</h4>
                <table className="item-details-table">
                  <thead>
                    <tr>
                      <th>Color</th>
                      <th>Size</th>
                      <th>Quantity</th>
                      <th>Base Price</th>
                      <th>GST ({item.gst}%)</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.selections.map((selection, idx) => {
                      const { basePrice, gstAmount, total, isLowerPriceApplied } = 
                        calculateItemPrice(selection, totalQuantity);
                      return (
                        <tr key={idx}>
                          <td>{selection.color}</td>
                          <td>{selection.size}</td>
                          <td>{selection.quantity}</td>
                          <td>
                            ₹{basePrice}
                            <div className="price-note">
                              ({isLowerPriceApplied ? 'Lower Price' : 'Base Price'})
                            </div>
                          </td>
                          <td>₹{gstAmount}</td>
                          <td>₹{total}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <div className="order-totals">
            <div className="total-row grand-total">
              <span>Grand Total:</span>
              <span>₹{grandTotal}</span>
            </div>
          </div>
        </div>

        <div className="customer-summary">
          <h3>Customer Details</h3>
          <div className="details-grid">
            <div>
              <strong>Name:</strong> {customerDetails.name}
            </div>
            <div>
              <strong>Phone:</strong> {customerDetails.phone}
            </div>
            <div>
              <strong>Email:</strong> {customerDetails.email}
            </div>
            <div>
              <strong>Address:</strong> {customerDetails.address}
            </div>
            <div>
              <strong>PIN Code:</strong> {customerDetails.pinCode}
            </div>
            <div>
              <strong>State:</strong> {customerDetails.state}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="place-order-container">
      {orderStep !== null && (
        <OrderLoader currentStep={orderStep} />
      )}
      
      <Stepper steps={steps} currentStep={currentStep} />
      
      {orderSuccess && (
        <div className="success-message">
          Order placed successfully! Redirecting...
        </div>
      )}

      <div className="step-content">
        {currentStep === 0 && (
          <ProductSelection onNext={handleNext} />
        )}
        
        {currentStep === 1 && (
          <div className="customer-details-step">
            <div className="phone-search">
              <div className="search-input">
                <input
                  type="tel"
                  name="phone"
                  value={customerDetails.phone}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                    handleInputChange({ target: { name: 'phone', value }});
                  }}
                  placeholder="Enter Phone Number (10 digits)"
                  maxLength="10"
                  required
                />
                <button 
                  onClick={() => searchCustomer(customerDetails.phone)}
                  className="btn-search"
                  disabled={customerDetails.phone.length !== 10 || isSearching}
                >
                  {isSearching ? 'Searching...' : 'Search'}
                </button>
              </div>
            </div>

            <div className="customer-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={customerDetails.name}
                  onChange={handleInputChange}
                  placeholder="Customer Name"
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={customerDetails.email}
                  onChange={handleInputChange}
                  placeholder="Email Address"
                  required
                />
              </div>

              <div className="form-group">
                <textarea
                  name="address"
                  value={customerDetails.address}
                  onChange={handleInputChange}
                  placeholder="Shipping Address"
                  required
                />
              </div>

              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    name="pinCode"
                    value={customerDetails.pinCode}
                    onChange={handleInputChange}
                    placeholder="PIN Code"
                    maxLength="6"
                    required
                    className={!isPinCodeValid && customerDetails.pinCode ? 'invalid-input' : ''}
                  />
                  {!isPinCodeValid && customerDetails.pinCode && (
                    <span className="error-message">Please enter a valid 6-digit PIN code</span>
                  )}
                </div>

                <div className="form-group">
                  <select
                    name="state"
                    value={customerDetails.state}
                    onChange={handleInputChange}
                    required
                    className="state-dropdown"
                  >
                    <option value="">Select State</option>
                    {INDIAN_STATES.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="gstNumber"
                  value={customerDetails.gstNumber}
                  onChange={handleInputChange}
                  placeholder="GST Number (Optional)"
                />
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && <ReviewStep />}
      </div>

      <div className={`step-navigation ${currentStep > 0 ? 'with-back' : ''}`}>
        {currentStep > 0 && (
          <button onClick={handleBack} className="btn-back">
            Back
          </button>
        )}
        
        {currentStep < steps.length - 1 ? (
          <button 
            onClick={handleNext}
            className="btn-next"
            disabled={
              (currentStep === 0 && cartItems.length === 0) ||
              (currentStep === 1 && !customerDetails.name)
            }
          >
            Next
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            className="btn-submit"
            disabled={loading}
          >
            {loading ? 'Placing Order...' : 'Place Order'}
          </button>
        )}
      </div>
    </div>
  );
};

export default PlaceOrder; 