import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
          main: '#33387C',
          dark: '#262961', // darker shade for hover states
          light: '#4B4F9A', // lighter shade if needed
          contrastText: '#ffffff',
        },
    },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(51, 56, 124, 0.04)',
          },
        },
      },
    },
  },
});

export default theme; 