import React from 'react';
import './CheckoutLoader.css';
import { MdOutlineShoppingCart, MdPayment, MdCheck } from 'react-icons/md';

const CheckoutLoader = ({ currentStep }) => {
  const steps = [
    { icon: <MdOutlineShoppingCart size={24} />, label: 'Processing Order' },
    { icon: <MdPayment size={24} />, label: 'Initiating Payment' },
    { icon: <MdCheck size={24} />, label: 'Completing Checkout' }
  ];

  return (
    <div className="checkout-loader">
      <div className="checkout-steps">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className={`checkout-step ${index === currentStep ? 'active' : ''} 
              ${index < currentStep ? 'completed' : ''}`}
          >
            <div className="step-icon">{step.icon}</div>
            <div className="step-label">{step.label}</div>
            {index < steps.length - 1 && <div className="step-line" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckoutLoader; 