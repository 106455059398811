import { Navigate } from "react-router-dom";
import useAdminRole from "../hooks/useAdminRole";

const ProtectedRoute = ({ children, requiredPermissions = [] }) => {
  const {  permissions, loading } = useAdminRole();

  if (loading) return <div>Loading...</div>;

  // Check if the user has the required permissions
  const hasPermissions = requiredPermissions.every(permission => permissions.includes(permission));

  if (hasPermissions) {
    return children;  // Render the protected component
  } else {
    return <Navigate to="/not-authorized" />;  // Redirect to "Not Authorized" page if permission check fails
  }
};

export default ProtectedRoute;
