import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { useToast } from '../context/ToastContext';
import './CustomerProfile.css';

const CustomerProfile = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [customer, setCustomer] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        // Fetch customer details
        const customerDoc = await getDoc(doc(db, 'users', customerId));
        if (!customerDoc.exists()) {
          toast.error('Customer not found');
          navigate('/admin/manage-users');
          return;
        }
        const customerData = { id: customerDoc.id, ...customerDoc.data() };
        setCustomer(customerData);
        setEditData(customerData);

        // Fetch customer's orders
        const ordersQuery = query(
          collection(db, 'orders'),
          where('userId', '==', customerId)
        );
        const orderSnapshot = await getDocs(ordersQuery);
        const ordersList = orderSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setOrders(ordersList);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        toast.error('Error loading customer data');
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [customerId, navigate, toast]);

  const handleSaveChanges = async () => {
    try {
      const customerRef = doc(db, 'users', customerId);
      await updateDoc(customerRef, {
        billingName: editData.billingName,
        email: editData.email,
        address: editData.address
      });
      setCustomer(editData);
      setIsEditing(false);
      toast.success('Customer details updated successfully');
    } catch (error) {
      console.error('Error updating customer:', error);
      toast.error('Failed to update customer details');
    }
  };

  if (loading) return <div className="loading">Loading customer profile...</div>;

  return (
    <div className="customer-profile-container">
      <div className="profile-header">
        <button onClick={() => navigate('/admin/manage-users')} className="back-button">
          ← Back to Users
        </button>
        <h2>Customer Profile</h2>
      </div>

      <div className="profile-content">
        <div className="customer-details">
          <h3>Customer Information</h3>
          {isEditing ? (
            <div className="edit-form">
              <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  value={editData.billingName || ''}
                  onChange={(e) => setEditData({...editData, billingName: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  value={editData.email || ''}
                  onChange={(e) => setEditData({...editData, email: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label>Mobile:</label>
                <input
                  type="tel"
                  value={editData.mobileNumber || ''}
                  disabled
                  className="disabled-input"
                />
                <small className="input-note">Phone number cannot be modified</small>
              </div>
              <div className="form-group">
                <label>Address:</label>
                <textarea
                  value={editData.address || ''}
                  onChange={(e) => setEditData({...editData, address: e.target.value})}
                />
              </div>
              <div className="button-group">
                <button onClick={handleSaveChanges} className="btn-save">Save Changes</button>
                <button onClick={() => {
                  setIsEditing(false);
                  setEditData(customer);
                }} className="btn-cancel">Cancel</button>
              </div>
            </div>
          ) : (
            <div className="info-display">
              <p><strong>Name:</strong> {customer.billingName}</p>
              <p><strong>Email:</strong> {customer.email}</p>
              <p><strong>Mobile:</strong> {customer.mobileNumber}</p>
              <p><strong>Address:</strong> {customer.address}</p>
              <button onClick={() => setIsEditing(true)} className="btn-edit">
                Edit Details
              </button>
            </div>
          )}
        </div>

        <div className="order-history">
          <h3>Order History</h3>
          {orders.length > 0 ? (
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{new Date(order.orderDate.seconds * 1000).toLocaleDateString()}</td>
                    <td>₹{order.totalAmount}</td>
                    <td>{order.status}</td>
                    <td>
                      <button 
                        onClick={() => navigate(`/admin/order-details/${order.id}`)}
                        className="btn-view-order"
                      >
                        View Order
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="no-orders">No orders found for this customer.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile; 