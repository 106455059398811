import React, { useState, useEffect } from 'react';
import './Checkout.css';
import { MdOutlineLocalShipping, MdOutlineLabel } from 'react-icons/md';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../firebase-config';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../../store/slices/cartSlice';
import { useNavigate } from 'react-router-dom';
import { INDIAN_STATES } from '../../constants/states';
import CheckoutLoader from '../../components/CheckoutLoader/CheckoutLoader';
import { FaShoppingBag } from 'react-icons/fa';

const functions = getFunctions(app, "asia-south1");
const checkoutFunction = httpsCallable(functions, 'checkout');
const updateOrderStatusFunction = httpsCallable(functions, 'updateOrderStatus');

const Checkout = () => {
  const { cartItems, totalQuantity, totalPrice } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deliveryDetails, setDeliveryDetails] = useState({
    billingName: '',
    mobileNumber: '',
    email: '',
    address: '',
    gstNumber: '',
    courierAddress: '',
  });

  const [shippingMethod, setShippingMethod] = useState('normal');
  const [dropshippingLabel, setDropshippingLabel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pinCode, setPinCode] = useState('');
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [selectedState, setSelectedState] = useState('');
  const [checkoutStep, setCheckoutStep] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'pinCode') {
      setPinCode(value);
      setIsPinCodeValid(validatePinCode(value));
    } else {
      setDeliveryDetails({
        ...deliveryDetails,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (!document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const handleShippingMethodChange = (e) => {
    setShippingMethod(e.target.value);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1048576 && /image|pdf/.test(file.type)) {
      setDropshippingLabel(file);
    } else {
      alert('Only .jpg, .png, .pdf files are allowed, and file size must be under 1MB.');
    }
  };

  const handlePayment = async (razorpayOrderId, totalAmount, orderId, mobileNumber, billingName) => {
    const options = {
      key: 'rzp_test_lLNcldjgv8qrZH',
      amount: totalAmount * 100,
      currency: "INR",
      name: "Your Store Name",
      description: "Test Transaction",
      order_id: razorpayOrderId,
      handler: async function (response) {
        try {
          await updateOrderStatusFunction({
            razorpayOrderId: razorpayOrderId,
            orderId: orderId,
            status: 'Pending',
            mobileNumber: mobileNumber,
            billingName: billingName
          });
          
          dispatch(clearCart());
          
          navigate('/success', {
            state: {
              orderDetails: {
                orderId: orderId,
                totalAmount: totalAmount,
                itemCount: cartItems.length,
                shippingMethod: shippingMethod,
                email: deliveryDetails.email,
                phone: deliveryDetails.mobileNumber
              }
            }
          });
        } catch (error) {
          console.error('Error updating order status:', error);
          alert('Payment was successful, but an error occurred while updating the order status.');
        }
      },
      modal: {
        ondismiss: async function () {
          try {
            await updateOrderStatusFunction({ razorpayOrderId : null, orderId: orderId, status: 'Payment_Failed', mobileNumber: mobileNumber, billingName: billingName });
            alert('Payment was cancelled. Rolling back changes.');
          } catch (error) {
            console.error('Error updating order status:', error);
            alert('Payment cancellation occurred, but an error occurred while updating the order status.');
          }
        },
      },
      prefill: {
        name: deliveryDetails.billingName,
        email: deliveryDetails.email,
        contact: deliveryDetails.mobileNumber,
      },
      theme: {
        color: "#3399cc",
      },
    };
  
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isPinCodeValid) {
      alert('Please enter a valid PIN code');
      return;
    }
    
    setLoading(true);
    setCheckoutStep(0);
  
    try {
      const response = await checkoutFunction({
        billingName: deliveryDetails.billingName,
        mobileNumber: deliveryDetails.mobileNumber,
        email: deliveryDetails.email,
        address: deliveryDetails.address,
        gstNumber: deliveryDetails.gstNumber,
        courierAddress: shippingMethod === 'courier' ? deliveryDetails.courierAddress : null,
        items: cartItems,
        totalAmount: totalPrice,
        shippingMethod,
        pinCode: pinCode,
        state: selectedState,
      });

      setCheckoutStep(1);
      
      const { razorpayOrderId, totalAmount, orderId, mobileNumber, billingName } = response.data;
      await handlePayment(razorpayOrderId, totalAmount, orderId, mobileNumber, billingName);
      
      setCheckoutStep(2);
      
    } catch (error) {
      console.error('Checkout or Payment Error:', error);
      alert('An error occurred during checkout. Please try again.');
      setCheckoutStep(null);
    } finally {
      setLoading(false);
    }
  };

  const validatePinCode = (pin) => {
    const pinCodeRegex = /^[1-9][0-9]{5}$/;
    return pinCodeRegex.test(pin);
  };

  const renderOrderSummary = () => {
    return (
      <div className="card-section order-summary">
        <h3><FaShoppingBag /> Order Summary</h3>
        <div className="summary-content">
          <div className="summary-row">
            <span>Items ({totalQuantity})</span>
            <span>₹{totalPrice}</span>
          </div>
          <div className="summary-row">
            <span>Shipping</span>
            <span>-</span>
          </div>
          <div className="summary-divider" />
          <div className="summary-row total">
            <span>Total</span>
            <span>₹{totalPrice}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="checkout-container">
      {checkoutStep !== null && (
        <CheckoutLoader currentStep={checkoutStep} />
      )}
      
      <h2 className="page-title">Checkout Page - Delivery Details</h2>
      
      {renderOrderSummary()}
      
      {loading && <div className="loader">Processing your order...</div>}
      <form className="delivery-form" onSubmit={handleSubmit}>
        <div className="card-section">
          <h3>Personal Details</h3>
          
          <div className="form-group full-width">
            <input
              type="text"
              name="billingName"
              value={deliveryDetails.billingName}
              onChange={handleInputChange}
              placeholder="Enter your full name"
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="mobileNumber"
                value={deliveryDetails.mobileNumber}
                onChange={handleInputChange}
                placeholder="Enter your 10-digit mobile number"
                pattern="\d{10}"
                maxLength="10"
                required
                title="Please enter a 10-digit mobile number"
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                name="email"
                value={deliveryDetails.email}
                onChange={handleInputChange}
                placeholder="Enter your email address"
                required
              />
            </div>
          </div>

          <div className="form-group full-width">
            <input
              type="string"
              name="gst"
              value={deliveryDetails.gstNumberemail}
              onChange={handleInputChange}
              placeholder="Enter your GST Number"
            />
          </div>

          <div className="form-group full-width">
            <textarea
              name="address"
              value={deliveryDetails.address}
              onChange={handleInputChange}
              placeholder="Enter your full shipping address"
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <select
                name="state"
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                required
                className="state-dropdown"
              >
                <option value="">Select State</option>
                {INDIAN_STATES.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <input
                type="text"
                name="pinCode"
                value={pinCode}
                onChange={handleInputChange}
                placeholder="Enter PIN Code"
                maxLength="6"
                required
                className={!isPinCodeValid && pinCode ? 'invalid-input' : ''}
              />
              {!isPinCodeValid && pinCode && (
                <span className="error-message">Please enter a valid 6-digit PIN code</span>
              )}
            </div>
          </div>
        </div>

        <div className="card-section">
          <h3><MdOutlineLocalShipping /> Shipping Method</h3>
          <div className="form-group">
            <select
              name="shippingMethod"
              value={shippingMethod}
              onChange={(e) => setShippingMethod(e.target.value)}
              required
              className="shipping-dropdown"
            >
              <option value="">Select Shipping Method</option>
              <option value="normal">Normal Delivery - We handle delivery to your address</option>
              <option value="courier">Courier Address - Provide a courier address</option>
              <option value="delhiBike">Delhi, within 2 hours delivery, by bike</option>
              <option value="dropshipping">Dropshipping - Upload your shipping label</option>
            </select>
          </div>
        </div>

        {shippingMethod === 'courier' && (
          <div className="card-section">
            <h3>Courier Address</h3>
            <div className="form-group">
              <label>Courier Address*</label>
              <textarea
                name="courierAddress"
                value={deliveryDetails.courierAddress}
                onChange={handleInputChange}
                placeholder="Enter the courier service address"
                required
              />
            </div>
          </div>
        )}

        {shippingMethod === 'dropshipping' && (
          <div className="card-section dropshipping-section">
            <h3><MdOutlineLabel /> Dropshipping Details</h3>
            <label>Upload Your Shipping Label (Only .jpg, .png, or .pdf, Max: 1MB):</label>
            <input type="file" accept=".jpg,.png,.pdf" onChange={handleFileUpload} />
            {dropshippingLabel && <p>Uploaded: {dropshippingLabel.name}</p>}
          </div>
        )}

        <button type="submit" className="btn-submit">Next</button>
      </form>
    </div>
  );
};

export default Checkout;
