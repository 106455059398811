import React, { useState, useEffect } from 'react';
import './MultiSelectDropdown.css';

const MultiSelectDropdown = ({ options, selected, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    const closeDropdown = () => setIsOpen(false);
    if (isOpen) {
      document.addEventListener('click', closeDropdown);
    }
    return () => document.removeEventListener('click', closeDropdown);
  }, [isOpen]);

  return (
    <div className="multi-select-dropdown">
      <div 
        className={`dropdown-header ${disabled ? 'disabled' : ''}`} 
        onClick={toggleDropdown}
      >
        <span>{selected.length ? `${selected.length} selected` : 'Select permissions'}</span>
        <span className="dropdown-arrow">▼</span>
      </div>
      {isOpen && !disabled && (
        <div className="dropdown-options" onClick={e => e.stopPropagation()}>
          {options.map(option => (
            <label key={option} className="dropdown-option">
              <input
                type="checkbox"
                checked={selected.includes(option)}
                onChange={() => onChange(option)}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
      )}
      {selected.length > 0 && (
        <div className="selected-items">
          {selected.join(', ')}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown; 