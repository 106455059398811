import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../../store/slices/cartSlice';
import './ProductSelection.css';

const ProductSelection = ({ onNext }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (cartItems.length > 0) {
      const lastProduct = products.find(p => p.id === cartItems[cartItems.length - 1].id);
      if (lastProduct) {
        setSelectedProduct(lastProduct);
        
        const restoredSkus = lastProduct.skus
          .filter(sku => {
            const selection = cartItems
              .find(item => item.id === lastProduct.id)
              ?.selections.find(s => s.skuId === sku.skuId);
            return selection && selection.quantity > 0;
          })
          .map(sku => {
            const selection = cartItems
              .find(item => item.id === lastProduct.id)
              ?.selections.find(s => s.skuId === sku.skuId);
            return {
              ...sku,
              quantity: selection.quantity,
              basePrice: selection.basePrice,
              lowerPrice: selection.lowerPrice,
              gst: lastProduct.gst || 0,
            };
          });
        
        setSelectedSkus(restoredSkus);
      }
    }
  }, [products, cartItems]);

  const fetchProducts = async () => {
    try {
      const productsSnapshot = await getDocs(collection(db, 'parent_products'));
      const productList = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productList);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setSelectedSkus([]);
  };

  const handleQuantityChange = (sku, quantity) => {
    const updatedSkus = selectedSkus.filter(s => s.skuId !== sku.skuId);
    if (quantity > 0) {
      updatedSkus.push({
        ...sku,
        quantity: parseInt(quantity),
        basePrice: parseInt(sku.pricing[0].price),
        lowerPrice: parseInt(sku.pricing[1].price),
        gst: selectedProduct.gst || 0,
      });
    }
    setSelectedSkus(updatedSkus);
  };

  const handleAddToCart = () => {
    if (selectedSkus.length === 0) {
      alert('Please select at least one SKU with quantity');
      return;
    }

    dispatch(addToCart({
      id: selectedProduct.id,
      name: selectedProduct.name,
      gst: selectedProduct.gst || 0,
      selections: selectedSkus.map(sku => ({
        skuId: sku.skuId,
        color: sku.color,
        size: sku.size,
        quantity: sku.quantity,
        basePrice: parseInt(sku.basePrice),
        lowerPrice: parseInt(sku.lowerPrice),
        gst: selectedProduct.gst || 0,
        imageUrl: sku.imageUrl,
      }))
    }));

    setSelectedProduct(null);
    setSelectedSkus([]);
  };

  const validateProductSelection = () => {
    if (cartItems.length === 0) {
      alert('Please select at least one product');
      return false;
    }

    // Check if any selected quantity exceeds available stock
    for (const item of cartItems) {
      for (const selection of item.selections) {
        const product = products.find(p => p.id === item.id);
        const sku = product?.skus.find(s => s.skuId === selection.skuId);
        
        if (!sku || selection.quantity > sku.stock) {
          alert(`Invalid quantity selected for ${item.name} - ${selection.color} ${selection.size}`);
          return false;
        }
      }
    }

    return true;
  };

  const handleNext = () => {
    if (!validateProductSelection()) return;
    onNext();
  };

  if (loading) return <div>Loading products...</div>;

  return (
    <div className="product-selection">
      <div className="products-grid">
        {products.map(product => (
          <div 
            key={product.id} 
            className={`product-card ${selectedProduct?.id === product.id ? 'selected' : ''}`}
            onClick={() => handleProductSelect(product)}
          >
            <img src={product.imageUrl} alt={product.name} />
            <h3>{product.name}</h3>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div className="sku-selection">
          <h3>{selectedProduct.name} - Available SKUs</h3>
          <table className="sku-table">
            <thead>
              <tr>
                <th>Color</th>
                <th>Size</th>
                <th>Stock</th>
                <th>Base Price</th>
                <th>Bulk Price</th>
                <th>GST</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {selectedProduct.skus.map(sku => (
                <tr key={sku.skuId}>
                  <td>{sku.color}</td>
                  <td>{sku.size}</td>
                  <td>{sku.stock}</td>
                  <td>₹{sku.pricing[0].price}</td>
                  <td>₹{sku.pricing[1].price}</td>
                  <td>{selectedProduct.gst}%</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max={sku.stock}
                      value={selectedSkus.find(s => s.skuId === sku.skuId)?.quantity || ''}
                      onChange={(e) => handleQuantityChange(sku, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="sku-actions">
            <button onClick={handleAddToCart}>Add to Cart</button>
          </div>
        </div>
      )}

      <div className="navigation-buttons">
        <button onClick={handleNext} className="btn-next">
          Next: Customer Details
        </button>
      </div>
    </div>
  );
};

export default ProductSelection; 