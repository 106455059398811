import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    Badge 
  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { pages, settings } from './utils';
import { TitleTypography, primaryColor, headerBgColor, textColor } from './style';

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { cartItems, totalQuantity } = useSelector(state => state.cart);

  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(null);
    navigate(path);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const renderCart = () => {
    return(
        <Badge
            badgeContent={totalQuantity}
            color="error"
            sx={{
                '& .MuiBadge-badge': {
                    top: 20,
                    right: 10,
                    minWidth: '20px',
                    height: '20px',
                },
            }}
        >
            <ShoppingCartCheckoutIcon  
                sx={{ my: 3, mx: 2, color: location.pathname === '/cart' ? primaryColor : textColor, cursor: 'pointer' }} 
                onClick={() => navigate("/cart")}
            />
        </Badge>
    )
  }

  return (
    <AppBar position="sticky" sx={{ backgroundColor: headerBgColor }}>
      <Container maxWidth="xl" sx={{ padding: 0 }}>
        <Toolbar disableGutters>
          <TitleTypography
            variant="h6"
            noWrap
            onClick={() => {
              navigate("/");
            }}
          >
            Kaka Store
          </TitleTypography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#000000"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={() => handleCloseNavMenu(page.path)}>
                  <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 700,
              color: '#33387C',
              textDecoration: 'none',
              cursor: 'pointer',
              letterSpacing: 1,
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            Kaka Store
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={() => handleCloseNavMenu(page.path)}
                sx={{
                  my: 2,
                  color: location.pathname === page.path ? primaryColor : textColor,
                  display: 'block',
                  '&:hover': {
                    backgroundColor: 'transparent', // Remove button background on hover
                    color: primaryColor, // Change text color on hover
                  }
                }}
              >
                {page.name}
              </Button>
            ))}
            {renderCart()}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {renderCart()}
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
