import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../admin/ProtectedRoute';
import AdminDashboard from '../admin/AdminDashboard';
import ManageUsers from '../admin/ManageUsers';
import ManageProducts from '../admin/ManageProducts';
import ManageOrders from '../admin/ManageOrders';
import ManageInventory from '../admin/ManageInventory';
import ParentProductForm from '../admin/ParentProductForm';
import OrderDetails from '../admin/OrderDetails';
import ParentProductDetails from '../admin/ParentProductDetails';
import CustomerProfile from '../admin/CustomerProfile';
import PlaceOrder from '../admin/PlaceOrder';
import InventoryLogs from '../admin/components/ProductDetails/InventoryLogs';

function AdminRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute requiredRole="admin">
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manage-users"
        element={
          <ProtectedRoute requiredPermissions={["manage_users"]}>
            <ManageUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manage-products"
        element={
          <ProtectedRoute requiredPermissions={["manage_products"]}>
            <ManageProducts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manage-orders"
        element={
          <ProtectedRoute requiredPermissions={["manage_orders"]}>
            <ManageOrders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manage-inventory"
        element={
          <ProtectedRoute requiredPermissions={["manage_inventory"]}>
            <ManageInventory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-parent-product"
        element={
          <ProtectedRoute requiredPermissions={["manage_products"]}>
            <ParentProductForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-parent-product/:productId"
        element={
          <ProtectedRoute requiredPermissions={["manage_products"]}>
            <ParentProductForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order-details/:orderId"
        element={
          <ProtectedRoute requiredPermissions={["manage_inventory"]}>
            <OrderDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-details/:productId"
        element={
          <ProtectedRoute requiredPermissions={["manage_products"]}>
            <ParentProductDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customer-profile/:customerId"
        element={
          <ProtectedRoute requiredPermissions={["manage_users"]}>
            <CustomerProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/place-order"
        element={
          <ProtectedRoute>
            <PlaceOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inventory-logs/:productId"
        element={
          <ProtectedRoute requiredPermissions={["manage_inventory"]}>
            <InventoryLogs />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default AdminRoutes;
