import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';

function RefundPolicy() {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      {/* Header */}
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', mb: 3, fontWeight: 'bold', fontSize: {xs: '24px', md: '32px'}, color: '#33387C' }}>
        Refund and Returns Policy
      </Typography>

      {/* Refund and Returns Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
          Refund and Returns
        </Typography>
        <Typography>
          Please note, we are not a regular fashion brand. All designs are specially customized and made exclusively
          for you. Any return which comes to us can’t be sold to any other customer, that’s why we try our 100% to give
          you a high-quality product to make you 100% satisfied. Therefore, no refund policy is available.
        </Typography>
      </Box>
      <Divider />

      {/* Defective Garments Section */}
      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
          Defective Garments
        </Typography>
        <Typography>
          In case of any manufacturing defect, you can return your product within 7 days of receipt. You can get your
          replacement within 7 working days.
        </Typography>
      </Box>
      <Divider />

      {/* Late Deliveries Section */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
          Late Deliveries
        </Typography>
        <Typography>
          Please keep a sufficient buffer to avoid delayed deliveries. Shipments are typically made via road cargo and
          are aimed to deliver within 2 weeks. However, we cannot make any guarantees.
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography>
            Reasons for delays may include design issues, product stock, and delivery times. If your order doesn’t
            arrive in time for events like a birthday, wedding anniversary, or a colleague’s farewell party, this does
            not constitute a reason for a return.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            If you need something super-fast, request Air Delivery (at an additional cost) if available in your
            location to get your order as fast as possible.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default RefundPolicy;
