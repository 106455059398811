import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const OrderInformation = ({ 
  order, 
  orderId, 
  userDetails, 
  handleViewInvoice, 
  printOrderDetails, 
  printManifest,
  selectedDueDate,
  setSelectedDueDate,
  isEditingDueDate,
  setIsEditingDueDate,
  handleDueDateUpdate
}) => {
  // Helper function to safely convert date with better formatting
  const formatDate = (date) => {
    if (!date) return 'Not set';
    
    const dateObj = date instanceof Date ? date : date.toDate();
    if (!dateObj) return 'Invalid date';

    return dateObj.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Simpler format for due date
  const formatDueDate = (date) => {
    if (!date) return 'Not set';
    
    const dateObj = date instanceof Date ? date : date.toDate();
    if (!dateObj) return 'Invalid date';

    return dateObj.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <div className="order-summary card">
      <h3>Order Information</h3>
      <p><strong>Order ID:</strong> {orderId}</p>
      <p><strong>Order Date:</strong> {formatDate(order.orderDate)}</p>
      
      <div className="due-date-section">
        <strong>Due Date: </strong>
        {isEditingDueDate ? (
          <div className="due-date-editor">
            <DatePicker
              selected={selectedDueDate || (order.dueDate?.toDate?.() || new Date())}
              onChange={(date) => setSelectedDueDate(date)}
              minDate={new Date()}
              dateFormat="MMM d, yyyy"
              className="date-picker-input"
            />
            <div className="due-date-actions">
              <button 
                onClick={() => handleDueDateUpdate(selectedDueDate)}
                className="btn-save-date"
                disabled={order.status === 'Cancelled'}
              >
                Save
              </button>
              <button 
                onClick={() => {
                  setIsEditingDueDate(false);
                  setSelectedDueDate(null);
                }}
                className="btn-cancel-date"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="due-date-display">
            <span>{formatDueDate(order.dueDate)}</span>
            {order.status !== 'Cancelled' && (
              <button
                onClick={() => {
                  setIsEditingDueDate(true);
                  setSelectedDueDate(order.dueDate?.toDate?.() || new Date());
                }}
                className="btn-edit-date"
              >
                Edit
              </button>
            )}
          </div>
        )}
      </div>

      <p><strong>Total Amount:</strong> ₹{order.totalAmount}</p>
      <p><strong>Payment ID:</strong> {order.razorpayOrderId || 'Pending'}</p>
      <p><strong>Status:</strong> {order.status}</p>
      <p><strong>Assigned To:</strong> {order.assignedTo || 'Not assigned'}</p>
      {order.orderType === 'admin' && (
          <div className="info-row admin-order-info">
            <span className="label">Placed By:  </span>
            <span className="value">
              <span className="admin-badge">
                Admin Order
                {order.placedBy !== 'admin' && ` - ${order.placedBy}`}
              </span>
            </span>
          </div>
        )}
      <button onClick={handleViewInvoice} className="btn-view-invoice">
        View Invoice
      </button>
      <div className="download-buttons">
        <button onClick={printOrderDetails} className="btn-download">
          Print Order Details
        </button>
        <button onClick={printManifest} className="btn-download">
          Print Manifest
        </button>
      </div>
    </div>
  );
};

export default OrderInformation; 