import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Success.css';
import { MdCheckCircle, MdLocalShipping, MdEmail, MdPhone, MdWhatsapp, MdWarning } from 'react-icons/md';
import { FaBoxOpen, FaShoppingBag } from 'react-icons/fa';

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    // Get order details from location state
    if (location.state?.orderDetails) {
      setOrderDetails(location.state.orderDetails);
    }
  }, [location]);

  const handleTrackOrder = () => {
    navigate('/orderTracking');
  };

  const handleContinueShopping = () => {
    navigate('/');
  };

  // If no order details are available, show a simplified version
  if (!orderDetails) {
    return (
      <div className="success-container">
        <div className="success-card">
          <div className="success-header">
            <MdWarning className="success-icon warning" />
            <h2>No Order Details Available</h2>
            <p className="no-order-message">
              It seems you've reached this page directly. 
              To place an order, please browse our products and proceed through checkout.
            </p>
          </div>

          <div className="empty-success-actions">
            <button 
              className="continue-shopping-button primary" 
              onClick={handleContinueShopping}
            >
              <FaShoppingBag /> Browse Products
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Regular success page with order details
  return (
    <div className="success-container">
      <div className="success-card">
        <div className="success-header">
          <MdCheckCircle className="success-icon" />
          <h2>Order Placed Successfully!</h2>
          <p className="order-id">
            Order ID: {orderDetails.orderId}
          </p>
        </div>

        <div className="order-summary">
          <div className="summary-section">
            <h3><FaBoxOpen /> Order Summary</h3>
            <div className="summary-details">
              <p>Total Amount: ₹{orderDetails.totalAmount}</p>
              <p>Items: {orderDetails.itemCount} items</p>
              <p>Payment Method: Razorpay</p>
            </div>
          </div>

          <div className="summary-section">
            <h3><MdLocalShipping /> Delivery Information</h3>
            <div className="summary-details">
              <p>Shipping Method: {orderDetails.shippingMethod}</p>
              <p>Estimated Delivery: 3-5 business days</p>
            </div>
          </div>

          <div className="communication-info">
            <p><MdEmail /> Order confirmation sent to: {orderDetails.email}</p>
            <p><MdWhatsapp /> WhatsApp updates will be sent to: {orderDetails.phone}</p>
          </div>
        </div>

        <div className="next-steps">
          <h3>What's Next?</h3>
          <ul>
            <li>You will receive an order confirmation email shortly</li>
            <li>Track your order status through WhatsApp or our tracking page</li>
            <li>For any queries, contact our support team</li>
          </ul>
        </div>

        <div className="success-actions">
          <button className="track-order-button" onClick={handleTrackOrder}>
            Track Order
          </button>
          <button className="continue-shopping-button" onClick={handleContinueShopping}>
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
