import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyA0DMuo_sPkf6jTnzU_Cw9ADVqqfterkeo",
  authDomain: "bulktees-cf2f6.firebaseapp.com",
  projectId: "bulktees-cf2f6",
  storageBucket: "bulktees-cf2f6.appspot.com",
  messagingSenderId: "475539886682",
  appId: "bulktees-cf2f6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "asia-south1");

// Connect to emulators in development
if (process.env.NODE_ENV === 'development') {
  const firestoreHost = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;
  const firestorePort = Number(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT);
  const authEmulatorHost = process.env.REACT_APP_AUTH_EMULATOR_HOST;
  const storageHost = process.env.REACT_APP_STORAGE_EMULATOR_HOST;
  const storagePort = Number(process.env.REACT_APP_STORAGE_EMULATOR_PORT);
  const functionsHost = process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST;
  const functionsPort = Number(process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT);

  // Connect Firestore to the local emulator
  connectFirestoreEmulator(db, firestoreHost, firestorePort);

  // Connect Auth to the local emulator
  connectAuthEmulator(auth, authEmulatorHost);

  // Connect Storage to the local emulator
  connectStorageEmulator(storage, storageHost, storagePort);

  // Connect Functions to the local emulator
  connectFunctionsEmulator(functions, 'localhost', functionsPort);

  console.log('Connected to Firebase emulators');
}



export { db, app, auth, storage };
