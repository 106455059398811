import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase-config';

export const logInventoryChange = async ({
  productId,
  productName,
  skuId,
  previousStock,
  newStock,
  type,
  updatedBy,
  referenceId = null
}) => {
  try {
    await addDoc(collection(db, 'inventory_logs'), {
      productId,
      productName,
      skuId,
      previousStock,
      newStock,
      change: newStock - previousStock,
      type, // 'manual_update', 'order_placed', etc.
      updatedBy,
      referenceId,
      timestamp: new Date()
    });
  } catch (error) {
    console.error('Error logging inventory change:', error);
  }
}; 