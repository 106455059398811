import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Grid2,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, setCurrentEdit } from '../../store/slices/cartSlice';
import { useNavigate } from 'react-router-dom';

// Updated Styled Components
const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textAlign: "center",
  fontWeight: "bold",
  border: `1px solid ${theme.palette.common.white}`,
  padding: '12px 16px',
  whiteSpace: 'normal',
  wordBreak: 'break-word'
}));

const SubHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  textAlign: "center",
  fontWeight: "bold",
  border: `1px solid ${theme.palette.grey[400]}`,
  padding: '12px 16px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  '&:first-of-type': {
    borderRight: `2px solid ${theme.palette.grey[400]}`,
  },
  '&:nth-of-type(2)': {
    borderRight: `2px solid ${theme.palette.grey[400]}`,
  }
}));

const DataCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  border: `1px solid ${theme.palette.grey[300]}`,
}));

// Optional: Add a styled Table component for outer borders
const StyledTable = styled(Table)(({ theme }) => ({
  border: `2px solid ${theme.palette.grey[300]}`,
  tableLayout: 'fixed',
  '& .MuiTableCell-root': {
    padding: '16px',  // Add some padding for better spacing
  }
}));

// Transform function for the new JSON structure
const transformCartData = (cartItems) => {
  return cartItems.map(item => ({
    product: item.name,
    colors: item.selections.reduce((acc, selection) => {
      const colorEntry = acc.find(c => c.color === selection.color);
      if (colorEntry) {
        colorEntry.sizes[selection.size] = selection.quantity;
      } else {
        acc.push({
          color: selection.color,
          sizes: { [selection.size]: selection.quantity }
        });
      }
      return acc;
    }, [])
  }));
};

// Extract all unique sizes
const getAllSizes = (data) => {
  const sizes = new Set();
  data.forEach(item => 
    item.selections.forEach(selection => 
      sizes.add(selection.size)
    )
  );
  return Array.from(sizes).sort((a, b) => parseInt(a) - parseInt(b));
};

const DynamicTable = ({ cartData, onEdit, onDelete }) => {
  if (!cartData || cartData.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h6">Your cart is empty</Typography>
      </Box>
    );
  }

  const transformedData = transformCartData(cartData);
  const allSizes = getAllSizes(cartData);

  return (
    <TableContainer component={Paper}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <HeaderCell 
              rowSpan={2}
              sx={{ 
                minWidth: '200px',
                maxWidth: '300px',
                width: '25%' 
              }}
            >
              Product
            </HeaderCell>
            <HeaderCell rowSpan={2}>Color</HeaderCell>
            {allSizes.map((size) => (
              <HeaderCell key={size}>Size {size}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {transformedData.map((product, productIndex) => (
            <React.Fragment key={productIndex}>
              {product.colors.map((color, colorIndex) => (
                <TableRow key={`${productIndex}-${colorIndex}`}>
                  {colorIndex === 0 && (
                    <SubHeaderCell 
                      rowSpan={product.colors.length}
                      sx={{
                        minWidth: '200px',
                        maxWidth: '300px',
                        width: '25%',
                        position: 'relative'
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 0.5
                      }}>
                        <Typography 
                          variant="subtitle2" 
                          fontWeight="medium"
                          sx={{ 
                            wordBreak: 'break-word',
                            width: '100%',
                            textAlign: 'center'
                          }}
                        >
                          {product.product}
                        </Typography>
                        <Box sx={{ 
                          display: 'flex', 
                          gap: 1,
                          mt: 0.5
                        }}>
                          <Tooltip title="Edit">
                            <IconButton 
                              size="small"
                              color="primary"
                              onClick={() => onEdit(cartData[productIndex])}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton 
                              size="small"
                              color="error"
                              onClick={() => onDelete(cartData[productIndex].id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </SubHeaderCell>
                  )}
                  <SubHeaderCell>{color.color}</SubHeaderCell>
                  {allSizes.map((size) => (
                    <DataCell key={size}>
                      {color.sizes[size] || ''}
                    </DataCell>
                  ))}
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const OrderSummary = ({ 
  totalQuantity, 
  totalPrice, 
  cartItems,
  navigateToCheckout 
}) => {
  // Calculate subtotal and GST
  const calculateTotals = () => {
    return cartItems.reduce((acc, item) => {
      const itemTotals = item.selections.reduce((selAcc, selection) => {
        const basePrice = parseInt(totalQuantity > 15 ? selection.lowerPrice : selection.basePrice);
        const quantity = selection.quantity;
        const subtotalAmount = basePrice * quantity;
        const gstAmount = (selection.gst / 100) * subtotalAmount;

        return {
          subtotal: selAcc.subtotal + subtotalAmount,
          gst: selAcc.gst + gstAmount
        };
      }, { subtotal: 0, gst: 0 });

      return {
        subtotal: acc.subtotal + itemTotals.subtotal,
        gst: acc.gst + itemTotals.gst
      };
    }, { subtotal: 0, gst: 0 });
  };

  const { subtotal, gst } = calculateTotals();

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 3, 
        height: '100%',
        backgroundColor: theme => theme.palette.grey[50],
        borderRadius: 2
      }}
    >
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ 
          fontWeight: 600,
          borderBottom: theme => `2px solid ${theme.palette.primary.main}`,
          pb: 1
        }}
      >
        Order Summary
      </Typography>
      
      <List disablePadding>
        {/* Product-wise breakdown */}
        {cartItems.map((item) => (
          <ListItem 
            key={item.id} 
            sx={{ 
              py: 1.5,
              px: 0,
              borderBottom: '1px solid #eee'
            }}
          >
            <ListItemText
              primary={
                <Typography variant="subtitle2" fontWeight={500}>
                  {item.name}
                </Typography>
              }
              secondary={
                <Box sx={{ mt: 0.5 }}>
                  {item.selections.map((selection, idx) => {
                    const itemPrice = parseInt(totalQuantity > 15 ? selection.lowerPrice : selection.basePrice);
                    return (
                      <Typography 
                        key={idx} 
                        variant="caption" 
                        display="block" 
                        color="text.secondary"
                      >
                        {selection.color} ({selection.size}) × {selection.quantity} = ₹{itemPrice * selection.quantity}
                      </Typography>
                    );
                  })}
                </Box>
              }
            />
            <Typography variant="body2" fontWeight={500}>
              ₹{item.selections.reduce((total, sel) => {
                const price = parseInt(totalQuantity > 15 ? sel.lowerPrice : sel.basePrice);
                return total + (price * sel.quantity);
              }, 0)}
            </Typography>
          </ListItem>
        ))}

        {/* Summary Section */}
        <Box sx={{ mt: 2, backgroundColor: '#f8f8f8', p: 2, borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Total Items
            </Typography>
            <Typography variant="body2">
              {totalQuantity}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Subtotal
            </Typography>
            <Typography variant="body2">
              ₹{Math.round(subtotal)}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              GST
            </Typography>
            <Typography variant="body2">
              ₹{Math.round(gst)}
            </Typography>
          </Box>
        </Box>

        {/* Total */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            mt: 2,
            pt: 2,
            borderTop: theme => `2px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            Total Amount
            <Typography 
              variant="caption" 
              display="block" 
              color="text.secondary"
              sx={{ mt: 0.5 }}
            >
              (Including GST)
            </Typography>
          </Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            ₹{Math.round(totalPrice)}
          </Typography>
        </Box>
      </List>

      <Button
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        onClick={navigateToCheckout}
        startIcon={<ShoppingCartCheckoutIcon />}
        sx={{ 
          mt: 3,
          py: 1.5,
          fontWeight: 600,
          boxShadow: 2
        }}
      >
        Proceed to Checkout
      </Button>
    </Paper>
  );
};

const CartDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartItems, totalQuantity, totalPrice } = useSelector((state) => state.cart);
  
  const handleEdit = (item) => {
    dispatch(setCurrentEdit(item));
    navigate(`/product/${item.id}`);
  };

  const handleRemoveFromCart = (id) => {
    dispatch(removeFromCart(id));
  };

  const navigateToCheckout = () => {
    navigate('/checkout');
  };

  const isEmpty = cartItems.length === 0;

  if (isEmpty) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h6">Your cart is empty</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Cart Details
      </Typography>
      <Grid2 container spacing={3}>
        <Grid2 item size={{ xs: 12, md: 9}}>
          <DynamicTable 
            cartData={cartItems} 
            onEdit={handleEdit}
            onDelete={handleRemoveFromCart}
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3}}>
          <OrderSummary 
            totalQuantity={totalQuantity}
            totalPrice={totalPrice}
            cartItems={cartItems}
            navigateToCheckout={navigateToCheckout}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default CartDetails;
