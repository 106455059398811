import React from 'react';

const ProductInformation = ({ product, productId }) => {
  return (
    <div className="product-summary card">
      <h3>Product Information</h3>
      <div className="product-info-grid">
        <div className="info-item">
          <strong>Product ID:</strong> {productId}
        </div>
        <div className="info-item">
          <strong>Name:</strong> {product.name}
        </div>
        <div className="info-item">
          <strong>HSN Code:</strong> {product.hsn}
        </div>
        <div className="info-item">
          <strong>GST:</strong> {product.gst}%
        </div>
        <div className="info-item">
          <strong>Weight:</strong> {product.weight}g
        </div>
        <div className="info-item">
          <strong>Categories:</strong> {product.categories.join(', ')}
        </div>
      </div>
      <div className="product-description">
        <strong>Description:</strong>
        <p>{product.description}</p>
      </div>
      <div className="product-image">
        {product.imageUrl && <img src={product.imageUrl} alt={product.name} />}
      </div>
    </div>
  );
};

export default ProductInformation; 