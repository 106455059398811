import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import './Invoice.css';
import { calculateItemPrice, calculateCartTotals } from '../utils/priceUtils';

const Invoice = () => {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const invoiceRef = doc(db, 'invoices', invoiceId);
        const invoiceDoc = await getDoc(invoiceRef);

        if (invoiceDoc.exists()) {
          setInvoice(invoiceDoc.data());
        } else {
          console.error('Invoice not found');
        }
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceId]);

  const handlePrint = () => {
    window.print();
  };

  if (loading) return <div>Loading invoice...</div>;
  if (!invoice) return <div>Invoice not found</div>;

  // Calculate total quantity for all items to determine pricing tier
  const totalQuantity = invoice.products.reduce((total, item) => {
    return total + item.selections.reduce((sum, sel) => sum + sel.quantity, 0);
  }, 0);

  // Group items by GST rate for tax breakdown
  const gstBreakdown = invoice.products.reduce((acc, item) => {
    item.selections.forEach(selection => {
      const { basePrice, gstAmount } = calculateItemPrice(selection, totalQuantity);
      const taxableAmount = basePrice * selection.quantity;
      const totalGstAmount = gstAmount * selection.quantity;
      
      if (!acc[selection.gst]) {
        acc[selection.gst] = {
          taxableAmount,
          gstAmount: totalGstAmount
        };
      } else {
        acc[selection.gst].taxableAmount += taxableAmount;
        acc[selection.gst].gstAmount += totalGstAmount;
      }
    });
    return acc;
  }, {});

  return (
    <div className="invoice-container">
      <div className="invoice-actions no-print">
        <button onClick={handlePrint} className="btn-print">
          Print Invoice
        </button>
      </div>

      <div className="invoice-content">
        <div className="invoice-header">
          <h1>Tax Invoice</h1>
          <div className="invoice-details">
            <p><strong>Invoice No:</strong> {invoice.invoiceNo}</p>
            <p><strong>Date:</strong> {invoice.date.toDate().toLocaleDateString()}</p>
          </div>
        </div>

        <div className="company-details">
          <h2>Trendy Dice</h2>
          <p>Company Address</p>
          <p>GST No: {invoice.gstNo}</p>
        </div>

        <div className="customer-details">
          <h3>Bill To:</h3>
          <p>User ID: {invoice.userId}</p>
          <p>Mobile: {invoice.mobileNumber}</p>
          <p>Address: {invoice.address}</p>
        </div>

        <table className="invoice-items">
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>GST %</th>
              <th>GST Amount</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoice.products.map((item, index) => (
              <React.Fragment key={index}>
                {item.selections.map((selection, idx) => {
                  const { basePrice, gstAmount, isLowerPriceApplied } = 
                    calculateItemPrice(selection, totalQuantity);
                  const amount = basePrice * selection.quantity;
                  const totalAmount = amount + (gstAmount * selection.quantity);

                  return (
                    <tr key={`${index}-${idx}`}>
                      <td>
                        {item.name} - {selection.color} ({selection.size})
                        <div className="price-note">
                          ({isLowerPriceApplied ? 'Lower Price Applied' : 'Base Price Applied'})
                        </div>
                      </td>
                      <td>{selection.quantity}</td>
                      <td>₹{basePrice}</td>
                      <td>{selection.gst}%</td>
                      <td>₹{(gstAmount * selection.quantity).toFixed(2)}</td>
                      <td>₹{totalAmount.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ))}
          </tbody>
        </table>

        <div className="invoice-summary">
          <div className="gst-breakdown">
            <h4>GST Breakdown</h4>
            <table className="gst-table">
              <thead>
                <tr>
                  <th>GST Rate</th>
                  <th>Taxable Amount</th>
                  <th>GST Amount</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(gstBreakdown).map(([rate, { taxableAmount, gstAmount }]) => (
                  <tr key={rate}>
                    <td>{rate}%</td>
                    <td>₹{taxableAmount.toFixed(2)}</td>
                    <td>₹{gstAmount.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="totals">
            {/* Calculate final totals */}
            {(() => {
              const { subtotal, totalGst, grandTotal } = calculateCartTotals(invoice.products);
              return (
                <>
                  <div className="total-row">
                    <span>Subtotal:</span>
                    <span>₹{subtotal.toFixed(2)}</span>
                  </div>
                  <div className="total-row">
                    <span>Total GST:</span>
                    <span>₹{totalGst.toFixed(2)}</span>
                  </div>
                  <div className="total-row grand-total">
                    <span>Grand Total:</span>
                    <span>₹{grandTotal.toFixed(2)}</span>
                  </div>
                </>
              );
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice; 