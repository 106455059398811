import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db, storage } from '../firebase-config'; 
import { collection, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './ProductForm.css';
import { useToast } from '../context/ToastContext'; // Import useToast
import { generateSlug } from '../utils/urlUtils'; // Add this import


const ParentProductForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { productId } = useParams();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    imageUrl: '',
    categories: [],
    gst: 0,
    hsn: '',
    weight: 0,
    slug: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (productId) {
      const fetchParentProduct = async () => {
        try {
          const productRef = doc(db, 'parent_products', productId);
          const productDoc = await getDoc(productRef);
          if (productDoc.exists()) {
            const productData = productDoc.data();
            setProduct(productData);
            setPreviewImage(productData.imageUrl || '');
          } else {
            console.error('Parent product not found');
            navigate('/admin/manage-products');
          }
        } catch (error) {
          console.error('Error fetching product:', error);
        }
      };

      fetchParentProduct();
    }
  }, [productId, navigate]);

  useEffect(() => {
    if (!product.slug && product.name) {
      setProduct(prev => ({
        ...prev,
        slug: generateSlug(product.name)
      }));
    }
  }, [product.name]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleCategoriesChange = (e) => {
    setProduct({ ...product, categories: e.target.value.split(',').map((cat) => cat.trim()) });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const uploadImage = async () => {
    if (!imageFile) return product.imageUrl;
    setUploading(true);
    const storageRef = ref(storage, `parent-product-images/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error('Error uploading image:', error);
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!product.hsn) {
        toast.error('HSN code is required');
        return;
      }
      

      const imageUrl = await uploadImage();
      const productData = { 
        ...product, 
        imageUrl,
        ...(product.slug && { slug: product.slug })
      };

      if (productId) {
        await updateDoc(doc(db, 'parent_products', productId), productData);
        toast.success('Product updated successfully');
      } else {
        await addDoc(collection(db, 'parent_products'), productData);
        toast.success('Product added successfully');
      }
      navigate('/admin/manage-products');
    } catch (error) {
      console.error('Error saving product:', error);
      toast.error('Failed to save product');
    }
  };

  return (
    <div className="product-form-container">
        <div className="product-form">
            <h2>{productId ? 'Edit Parent Product' : 'Add New Parent Product'}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-section">
                    <div className="form-group">
                        <label>Product Name</label>
                        <input
                            type="text"
                            name="name"
                            value={product.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>URL Slug (Optional)</label>
                        <input
                            type="text"
                            name="slug"
                            value={product.slug}
                            onChange={handleInputChange}
                            placeholder="custom-url-slug"
                        />
                        <small>Leave empty to auto-generate from product name</small>
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            name="description"
                            value={product.description}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-section">
                    <div className="form-group">
                        <label>Upload Image</label>
                        <input type="file" accept="image/*" onChange={handleImageChange} />
                        {previewImage && <img src={previewImage} alt="Preview" className="image-preview" />}
                    </div>
                </div>

                <div className="form-section">
                    <div className="form-group">
                        <label>HSN Code *</label>
                        <input
                            type="text"
                            name="hsn"
                            value={product.hsn}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter HSN Code"
                        />
                    </div>
                    <div className="form-group">
                        <label>Product Weight (in grams)</label>
                        <input
                            type="number"
                            name="weight"
                            value={product.weight}
                            onChange={handleInputChange}
                            min="0"
                            step="0.01"
                            placeholder="Enter product weight"
                        />
                    </div>
                    <div className="form-group">
                        <label>GST (%)</label>
                        <input
                            type="number"
                            name="gst"
                            value={product.gst}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-section">
                    <div className="form-group">
                        <label>Categories (comma-separated)</label>
                        <input
                            type="text"
                            name="categories"
                            value={product.categories.join(', ')}
                            onChange={handleCategoriesChange}
                        />
                    </div>
                </div>

                <div className="button-container">
                    <button type="submit" className="btn-submit" disabled={uploading}>
                        {uploading ? 'Uploading...' : productId ? 'Update Product' : 'Add Product'}
                    </button>
                </div>
            </form>
        </div>
    </div>
  );
};

export default ParentProductForm;
