import React from 'react';
import './Stepper.css';
import { MdShoppingCart, MdPerson, MdFactCheck } from 'react-icons/md';

const Stepper = ({ steps, currentStep }) => {
  const getStepIcon = (index) => {
    switch(index) {
      case 0:
        return <MdShoppingCart className="step-icon" />;
      case 1:
        return <MdPerson className="step-icon" />;
      case 2:
        return <MdFactCheck className="step-icon" />;
      default:
        return null;
    }
  };

  return (
    <div className="stepper">
      {steps.map((step, index) => (
        <div key={index} className="step-container">
          <div className={`step ${currentStep >= index ? 'active' : ''} ${currentStep === index ? 'current' : ''}`}>
            <div className="step-circle">
              {getStepIcon(index)}
              <div className="step-number">{index + 1}</div>
            </div>
            <div className="step-label">{step}</div>
          </div>
          {index < steps.length - 1 && (
            <div className={`connector ${currentStep > index ? 'active' : ''}`}>
              <div className="connector-line"></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper; 