import React from 'react';

const OrderedItems = ({ items }) => {
  return (
    <div className="order-items card">
      <h3>Ordered Items</h3>
      <table className="items-table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Color</th>
            <th>Size</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) =>
            item.selections.map((selection, selIndex) => (
              <tr key={`${index}-${selIndex}`}>
                <td data-label="Product Name">{item.name}</td>
                <td data-label="Color">{selection.color}</td>
                <td data-label="Size">{selection.size}</td>
                <td data-label="Quantity">{selection.quantity}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrderedItems; 