import React from 'react';
import './OrderLoader.css';
import { FaShoppingCart, FaUserCircle, FaCheckCircle } from 'react-icons/fa';

const OrderLoader = ({ currentStep }) => {
  const steps = [
    { icon: <FaShoppingCart />, label: 'Processing Order' },
    { icon: <FaUserCircle />, label: 'Creating Customer' },
    { icon: <FaCheckCircle />, label: 'Completing Order' }
  ];

  return (
    <div className="order-loader-overlay">
      <div className="order-loader-content">
        <div className="order-loader-steps">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className={`order-loader-step ${index <= currentStep ? 'active' : ''} 
                ${index < currentStep ? 'completed' : ''}`}
            >
              <div className="step-icon">
                {step.icon}
                {index < currentStep && <div className="check-mark">✓</div>}
              </div>
              <div className="step-label">{step.label}</div>
              {index < steps.length - 1 && <div className="step-connector" />}
            </div>
          ))}
        </div>
        <div className="loading-spinner" />
      </div>
    </div>
  );
};

export default OrderLoader; 