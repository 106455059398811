import { useEffect, useState } from "react";
import { doc, getDoc, query, collection, where, getDocs } from "firebase/firestore"; // <-- Add missing imports
import { auth, db } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";

const useAdminRole = () => {
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdminRole = async (user) => {
     
      const adminQuery = query(
        collection(db, "admin_users"),
        where("email", "==", user.email)
      );
      
      const adminSnap = await getDocs(adminQuery);
      
      
      if (!adminSnap.empty) {
        const adminData = adminSnap.docs[0].data(); 
        setRole(adminData.role);
        setPermissions(adminData.permissions || []);
      } else {
        setRole(null);
      }
      setLoading(false);
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchAdminRole(user);  // Fetch the admin role when the user is authenticated
      } else {
        setLoading(false);  // No user logged in
      }
    });

    return () => unsubscribe();
  }, []);

  return { role, permissions, loading };
};

export default useAdminRole;
