// src/admin/ManageProducts.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Link, useNavigate } from 'react-router-dom';
import './ManageProducts.css';
import { useToast } from '../context/ToastContext';
import useAdminRole from '../hooks/useAdminRole';

const ManageProducts = () => {
  const [parentProducts, setParentProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchParentProducts = async () => {
      try {
        const parentProductsCollection = collection(db, 'parent_products');
        const productSnapshot = await getDocs(parentProductsCollection);
        const productList = productSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setParentProducts(productList);
      } catch (error) {
        console.error('Error fetching parent products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchParentProducts();
  }, []);

  const handleRowClick = (productId) => {
    navigate(`/admin/product-details/${productId}`);
  };

  return (
    <div className="manage-products">
      <h2>Manage Products</h2>

      <div className="add-product-button">
        <Link to="/admin/add-parent-product" className="btn-add">Add New Parent Product</Link>
      </div>

      {loading ? (
        <p>Loading products...</p>
      ) : (
        <table className="products-table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Description</th>
              <th>Categories</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {parentProducts.length > 0 ? (
              parentProducts.map((product) => (
                <tr 
                  key={product.id} 
                  onClick={() => handleRowClick(product.id)}
                  className="clickable-row"
                >
                  <td data-label="Product Name">{product.name}</td>
                  <td data-label="Description">{product.description}</td>
                  <td data-label="Categories">{product.categories.join(', ')}</td>
                  <td data-label="Image">
                    {product.imageUrl ? (
                      <img src={product.imageUrl} alt={product.name} className="product-image" />
                    ) : (
                      'No Image'
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No parent products found.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ManageProducts;
