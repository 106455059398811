import React, { useState, useEffect } from 'react';
import { db } from '../../firebase-config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './OrderTracking.css';
import { useNavigate } from 'react-router-dom';
import { FaBox, FaShippingFast, FaCheckCircle, FaAngleDown, FaAngleUp, FaCopy } from 'react-icons/fa';
import { MdPendingActions } from 'react-icons/md';

const formatPhoneNumber = (number) => {
  const cleaned = number.replace(/\D/g, '');
  return cleaned.replace(/^91/, '');
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error('Failed to copy text: ', err);
    return false;
  }
};

const OrderTracking = () => {
  const [orderId, setOrderId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const navigate = useNavigate(); 
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [timer, setTimer] = useState(0);
  const [expandedOrders, setExpandedOrders] = useState(new Set());
  const [searchActive, setSearchActive] = useState(false);
  const [allOrders, setAllOrders] = useState([]); // Store all orders
  const functions = getFunctions(undefined, 'asia-south1');

  useEffect(() => {
    const verificationData = localStorage.getItem('orderTrackingVerification');
    if (verificationData) {
      const { mobileNumber: storedNumber, expiryTime } = JSON.parse(verificationData);
      
      if (new Date().getTime() < expiryTime) {
        setMobileNumber(storedNumber);
        setVerified(true);
        fetchAllOrders(storedNumber); // Fetch all orders for verified user
      } else {
        localStorage.removeItem('orderTrackingVerification');
      }
    }
  }, []);

  const fetchAllOrders = async (number) => {
    setLoading(true);
    try {
      const formattedNumber = formatPhoneNumber(number);
      const ordersRef = collection(db, 'orders');
      
      const queries = [
        query(ordersRef, where('shipping.phone', '==', formattedNumber)),
        query(ordersRef, where('shipping.phone', '==', `91${formattedNumber}`))
      ];
      
      const results = await Promise.all(queries.map(q => getDocs(q)));
      const fetchedOrders = results.flatMap(querySnapshot => 
        querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      );
      
      const uniqueOrders = [...new Map(fetchedOrders.map(order => 
        [order.id, order])).values()];
      
      setAllOrders(uniqueOrders);
      setOrders(uniqueOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
      alert('Unable to fetch orders. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && otpSent) {
      setOtpSent(false);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer, otpSent]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleInputChange = (e) => {
    setOrderId(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      setMobileNumber(value);
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSendOTP = async () => {
    if (mobileNumber.length !== 10) {
      alert('Please enter a valid 10-digit mobile number');
      return;
    }

    try {
      const generateOTP = httpsCallable(functions, 'generateOTP');
      await generateOTP({ mobileNumber });
      setOtpSent(true);
      setTimer(180);
    } catch (error) {
      console.error('Error sending OTP:', error);
      if (error.message.includes('No user found')) {
        alert('No orders found for this mobile number. Please check the number and try again.');
      } else {
        alert('Error sending OTP. Please try again.');
      }
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const verifyOTP = httpsCallable(functions, 'verifyOTP');
      const result = await verifyOTP({ mobileNumber, otp });
      
      if (result.data.success) {
        setVerified(true);
        const verificationData = {
          mobileNumber,
          expiryTime: new Date().getTime() + (24 * 60 * 60 * 1000),
        };
        localStorage.setItem('orderTrackingVerification', JSON.stringify(verificationData));
        fetchAllOrders(mobileNumber); // Fetch all orders after verification
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Invalid OTP. Please try again.');
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!orderId.trim()) {
      setOrders(allOrders);
      setSearchActive(false);
      return;
    }
    
    const filteredOrders = allOrders.filter(order => order.id.includes(orderId));
    setOrders(filteredOrders);
    setSearchActive(true);
  };

  const toggleOrderExpansion = (orderId) => {
    const newExpandedOrders = new Set(expandedOrders);
    if (newExpandedOrders.has(orderId)) {
      newExpandedOrders.delete(orderId);
    } else {
      newExpandedOrders.add(orderId);
    }
    setExpandedOrders(newExpandedOrders);
  };

  const handleViewInvoice = (invoiceId) => {
    navigate(`/invoice/${invoiceId}`);
  };

  const handleLogout = () => {
    setVerified(false);
    setMobileNumber('');
    setOrderId('');
    setOrders([]);
    setOtpSent(false);
    setOtp('');
    localStorage.removeItem('orderTrackingVerification');
  };

  const getOrderStatusIndex = (status) => {
    const statusMap = {
      'pending': 0,
      'processing': 0,
      'packed': 1,
      'shipped': 2,
      'delivered': 3
    };
    return statusMap[status?.toLowerCase()] || 0;
  };

  const OrderStatusTimeline = ({ status }) => {
    const currentStatusIndex = getOrderStatusIndex(status);
    
    const statusSteps = [
      { icon: <MdPendingActions size={20} />, label: 'Processing' },
      { icon: <FaBox size={20} />, label: 'Packed' },
      { icon: <FaShippingFast size={20} />, label: 'Shipped' },
      { icon: <FaCheckCircle size={20} />, label: 'Delivered' }
    ];

    return (
      <div className="order-status-timeline">
        {statusSteps.map((step, index) => (
          <div key={index} className="status-step">
            <div className={`status-icon ${index === currentStatusIndex ? 'active' : ''} ${index < currentStatusIndex ? 'completed' : ''}`}>
              {step.icon}
            </div>
            <div className={`status-label ${index === currentStatusIndex ? 'active' : ''}`}>
              {step.label}
            </div>
            <div className={`status-line ${index < currentStatusIndex ? 'completed' : ''}`} />
          </div>
        ))}
      </div>
    );
  };

  const OrderCard = ({ order }) => {
    const isExpanded = expandedOrders.has(order.id);
    const statusIndex = getOrderStatusIndex(order.status);

    const handleCopyClick = async (e) => {
      e.stopPropagation(); // Prevent the card from expanding when clicking copy
      const success = await copyToClipboard(order.id);
      if (success) {
        alert('Order ID copied to clipboard!');
      } else {
        alert('Failed to copy Order ID');
      }
    };

    return (
      <div className={`order-card ${isExpanded ? 'expanded' : ''}`}>
        <div 
          className="order-card-header"
          onClick={() => toggleOrderExpansion(order.id)}
        >
          <div className="header-content">
            <h3>
              Order ID: {order.id}
              <FaCopy 
                className="copy-icon"
                onClick={handleCopyClick}
                title="Copy Order ID"
              />
            </h3>
            <p className="order-date">
              {new Date(order.orderDate.seconds * 1000).toLocaleDateString()}
            </p>
          </div>
          {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
        </div>

        <div className={`order-details ${isExpanded ? 'show' : ''}`}>
          <OrderStatusTimeline status={order.status} />
          
          <div className="order-info">
            <div className="info-section">
              <h4>Order Summary</h4>
              <p>Total Amount: ₹{order.totalAmount}</p>
              <p>Items: {order.items.length}</p>
              <p>Status: {order.status || 'Processing'}</p>
            </div>

            <div className="info-section">
              <h4>Shipping Details</h4>
              <p>Method: {order.shipping.method}</p>
              <p>Address: {order.shipping.address}</p>
              {order.shipping.trackingLink && (
                <a 
                  href={order.shipping.trackingLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="tracking-link"
                >
                  Track Shipment
                </a>
              )}
            </div>

            {order.items.map((item, index) => (
              <div key={index} className="order-item">
                <p>{item.name}</p>
                <div className="item-selections">
                  {item.selections.map((selection, idx) => (
                    <span key={idx} className="selection-tag">
                      {selection.quantity}x - ₹{selection.price}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="order-tracking-container">
      <h2>Track Your Orders</h2>
      
      {verified ? (
        <>
          <div className="user-section">
            <p>Mobile: {mobileNumber}</p>
            <button onClick={handleLogout} className="btn-logout">
              Logout
            </button>
          </div>

          <div className="search-section">
            <form onSubmit={handleSearch} className="search-form">
              <div className="search-input-container">
                <input
                  type="text"
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  placeholder="Search by Order ID"
                  className="search-input"
                />
              </div>
              <button type="submit" className="search-button">
                Search
              </button>
              {searchActive && (
                <button 
                  type="button" 
                  onClick={() => {
                    setOrderId('');
                    setOrders(allOrders);
                    setSearchActive(false);
                  }}
                  className="clear-search"
                >
                  Clear Search
                </button>
              )}
            </form>
          </div>

          {loading ? (
            <div className="loader">Loading orders...</div>
          ) : (
            <div className="orders-list">
              {orders.length > 0 ? (
                orders.map(order => (
                  <OrderCard key={order.id} order={order} />
                ))
              ) : (
                <p className="no-orders">
                  {searchActive 
                    ? "No orders found matching your search." 
                    : "No orders found for this mobile number."}
                </p>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="verification-form">
          <h3>Verify Your Mobile Number</h3>
          <div className="form-group">
            <label>Enter Your Mobile Number:</label>
            <input
              type="tel"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              placeholder="Enter your mobile number"
              required
              maxLength="10"
              pattern="\d{10}"
            />
            {!otpSent && (
              <button 
                type="button" 
                onClick={handleSendOTP}
                className="btn-send-otp"
              >
                Send OTP
              </button>
            )}
            {otpSent && (
              <div className="timer">
                Resend OTP in {formatTime(timer)}
              </div>
            )}
          </div>

          {otpSent && (
            <div className="form-group">
              <label>Enter OTP:</label>
              <div className="otp-input-container">
                <input
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  placeholder="Enter OTP"
                  required
                  maxLength="6"
                />
                <button 
                  type="button" 
                  onClick={handleVerifyOTP}
                  className="btn-verify"
                >
                  Verify OTP
                </button>
              </div>
              <p className="otp-timer">OTP expires in {formatTime(timer)}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderTracking;