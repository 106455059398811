import * as React from 'react';
import { Box, Grid2, Link, Typography, Container, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: '#',
  twitter: '#',
  instagram: '#',
};

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: '#e6e7e869',
        // color: 'text.secondary',
        py: 3,
        marginTop: '38px',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth={false}>
        <Grid2 container spacing={2} justifyContent="space-between">
          <Grid2 item size={{ xs: 12, sm: 6, md: 3 }} >
            <Typography variant="h6" sx={{ color: "#33387C", fontFamily: "Roboto, sans-serif", fontWeight: "bold", letterSpacing: 1, }} gutterBottom>
              Kaka Store
            </Typography>
            {/* Add your logo component or image here */}
          </Grid2>
          <Grid2 item size={{ xs: 6, sm: 3, md: 2 }}>
            <Typography variant="subtitle1" sx={{ color: "#33387C", fontFamily: "Roboto, sans-serif", fontWeight: "bold", letterSpacing: 1, }} gutterBottom>
              PRODUCT
            </Typography>
            <Link href="#" color="inherit" display="block">Pricing</Link>
            <Link href="/faq" color="inherit" display="block">FAQ</Link>
          </Grid2>
          <Grid2 item size={{ xs: 6, sm: 3, md: 2 }}>
            <Typography variant="subtitle1" sx={{ color: "#33387C", fontFamily: "Roboto, sans-serif", fontWeight: "bold", letterSpacing: 1, }} gutterBottom>
              COMPANY
            </Typography>
            <Link href="#" color="inherit" display="block">About Us</Link>
            <Link href="#" color="inherit" display="block">Privacy Policy</Link>
            <Link href="/refund-policy" color="inherit" display="block">Refund Policy</Link>
          </Grid2>
          {/* <Grid2 item size={{ xs: 6, sm: 3, md: 2 }}>
            <Typography variant="subtitle1" sx={{ color: "#33387C", fontFamily: "Roboto, sans-serif", fontWeight: "bold", letterSpacing: 1, }} gutterBottom>
              DEVELOPERS
            </Typography>
            <Link href="#" color="inherit" display="block">Public API</Link>
            <Link href="#" color="inherit" display="block">Documentation</Link>
            <Link href="#" color="inherit" display="block">Guides</Link>
          </Grid2> */}
          <Grid2 item size={{ xs: 6, sm: 3, md: 2 }}>
            <Typography variant="subtitle1" sx={{ color: "#33387C", fontFamily: "Roboto, sans-serif", fontWeight: "bold", letterSpacing: 1, }} gutterBottom>
              SOCIAL MEDIA
            </Typography>
            <IconButton aria-label="Facebook" color="inherit" component="a" href={socialMediaLinks.facebook}>
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Twitter" color="inherit" component="a" href={socialMediaLinks.twitter}>
              <TwitterIcon />
            </IconButton>
            <IconButton aria-label="Instagram" color="inherit" component="a" href={socialMediaLinks.instagram}>
              <InstagramIcon />
            </IconButton>
          </Grid2>
        </Grid2>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ pt: 4 }}>
          © 2024 Company Co. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;