import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from '../../context/ToastContext';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/slices/cartSlice';
import { db } from '../../firebase-config';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';

export const useProductDetails = (id) => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cartItems, currentEdit } = useSelector((state) => state.cart);
    
    const [skuRows, setSkuRows] = useState([]);
    const [pricingTableRows, setPricingTableRows] = useState([]);
    const [parentProduct, setParentProduct] = useState(null);
    const [skus, setSkus] = useState([]);
    const [skuTableColumns, setSkuTableColumns] = useState([{
        field: "color",
        headerName: "Color",
        flex: 1,
        editable: false,
        sortable: false,
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
    }]);
    const [imageList, setImageList] = useState([]);
    const [selectedQuantities, setSelectedQuantities] = useState({});
    const [groupedSkusPricing, setGroupedSkusPricing] = useState({});
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    // Group SKUs by identical pricing (both min and max prices must match)
    const groupSkusByPricing = (skuList) => {
        const pricingGroups = {};

        skuList?.forEach(sku => {
            sku.pricing.forEach(priceTier => {
            const key = `${priceTier.minQuantity}-${priceTier.maxQuantity}:${priceTier.price}`;
            if (!pricingGroups[key]) {
                pricingGroups[key] = [];
            }
            pricingGroups[key].push(sku);
            });
        });
        setGroupedSkusPricing(pricingGroups)
    };

    const fetchSkus = (skusList) => {
        groupSkusByPricing(skusList);
        const colors = [...new Set(skusList?.map(item => item.color))];
        const sizes = [...new Set(skusList?.map(item => item.size))].sort();

        const skuTable = colors.map((color, index) => {
            const row = { id: index + 1, color };
            sizes.forEach(size => {
                row[`size${size}`] = 0;
                row[`stock$${size}`] = 0;
                row[size] = { id: null, stock: 0, docId: null, sku: null, pricing: [], imageUrl: null, parentId: null };
            });

            skusList.forEach(item => {
                if (item.color === color) {
                    row[`size${item.size}`] = 0;
                    row[`stock$${item.size}`] = item.stock;
                    row[item.size] = {
                        stock: item.stock,
                        docId: item.docId,
                        sku: item.sku,
                        pricing: item.pricing,
                        imageUrl: item.imageUrl,
                        parentId: item.parentId,
                        id: item.skuId
                    };
                }
            });

            return row;
        });

        // Handle edit mode if cart has item and if comes from home page
        if (cartItems.some((cItem) => cItem.id === id)) {
            const initialQuantities = {};
            const updatedSkuRows = skuTable.map((item) => {
              cartItems.find((cItem) => cItem.id === id).
                selections.forEach((selection) => {
                    if (item.color === selection.color) {
                        item[`size${selection.size}`] = selection.quantity;
                    }
                    initialQuantities[selection.id] = selection.quantity;
                });
                return item;
            });
            setSelectedQuantities(initialQuantities);
            setSkuRows(updatedSkuRows);
        } else {
            setSkuRows(skuTable);
        }

        const columns = [
            ...skuTableColumns,
            ...sizes?.map((size) => ({
                field: `size${size}`,
                headerName: size,
                flex: 1,
                editable: true,
                type: "number",
                sortable: false,
                headerClassName: "super-app-theme--header",
                disableColumnMenu: true,
            })),
        ];
        setSkuTableColumns(columns);
        setSkus(skusList);
    };

    const fetchParentProduct = async () => {
        if (!slug) {
          toast.error('Invalid product URL');
          navigate('/');
          return;
        }
        let productData = null;

        // First try to find by slug if it looks like a slug (contains hyphens)
        if (slug.includes('-')) {
          const q = query(collection(db, 'parent_products'), where('slug', '==', slug));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            productData = {
              id: querySnapshot.docs[0].id,
              ...querySnapshot.docs[0].data()
            };
          }
        }

        if (!productData) {
          const productRef = doc(db, 'parent_products', slug);
          const productDoc = await getDoc(productRef);

          if (productDoc.exists()) {
            productData = {
              id: productDoc.id,
              ...productDoc.data()
            };
          }
        }

        if (productData) {
          setParentProduct(productData);
          fetchSkus(productData?.skus);
        }
    };

    useEffect(() => {
        fetchParentProduct();
    }, [id]);

    useEffect(() => {
        if(Object.keys(groupedSkusPricing).length){
            const output = Object.entries(groupedSkusPricing).map(([key, items], index) => {
                const [range, price] = key.split(':');
                const sku = items.map(item => `${item.color} ${item.size}`).join(', ');
                return {
                    id: index + 1,
                    sku: `${sku},`,
                    range: range.replace('-', ' to '),
                    price: `${price}₹`
                };
            });
            setPricingTableRows(output);
        }
    }, [groupedSkusPricing]);

    useEffect(() => {
        if(parentProduct && skus.length > 0){
            const uniqueColors = skus.reduce((colors, sku) => {
                if (!colors.includes(sku.color)) {
                  colors.push(sku.color);
                }
                return colors;
            }, []);
            const imgList = {
                img: [{
                    img: parentProduct?.imageUrl,
                    key: parentProduct?.id,
                    alt: `${parentProduct?.name}`
                  }]
            };
            uniqueColors.forEach((color) => {
                const skuWithColor = skus.find((sku) => sku.color === color);
                imgList.img.push({
                    img: skuWithColor.imageUrl,
                    key: skuWithColor.id,
                    alt: `${skuWithColor.color} ${skuWithColor.size}`
                })
              })
              setImageList(imgList);
        }
    }, [skus, parentProduct])

    const calculatePrice = (pricing, quantity) => {
        const tier = pricing.find(
            (p) => quantity >= p.minQuantity && quantity <= p.maxQuantity
        );
        return tier ? tier.price : null;
    };

    const handleAddToCart = () => {
        const selections = skus
            .filter((sku) => selectedQuantities[sku.skuId] > 0)
            .map((sku) => {
                const basePrice = calculatePrice(sku.pricing, sku.pricing[0].minQuantity);
                const lowerPrice = calculatePrice(sku.pricing, sku.pricing[1].minQuantity);
                return {
                    id: sku.skuId,
                    color: sku.color,
                    size: sku.size,
                    quantity: selectedQuantities[sku.skuId],
                    gst: parentProduct.gst,
                    basePrice: basePrice || 0,
                    lowerPrice: lowerPrice || 0,
                };
            });

        if (selections.length === 0) {
            toast.error('Please select at least one SKU.');
            return;
        }

        const cartItem = {
            id: parentProduct.id,
            name: parentProduct.name,
            gst: parentProduct.gst,
            selections,
        };

        dispatch(addToCart(cartItem));
        toast.success(currentEdit ? 'Cart updated successfully' : 'Items added to the cart');
    };

    return {
        skuRows,
        setSkuRows,
        skuTableColumns,
        loading,
        skus,
        parentProduct,
        pricingTableRows,
        imageList,
        handleAddToCart,
        setSelectedQuantities,
        currentEdit
    };
};