export const skuTableRows = [
    { id: 1, color: "Orange", size38: 0, size40: 0 },
    { id: 2, color: "Black", size38: 0, size40: 0 },
];

export const skuTableColumns = [
    { 
      field: "color", 
      headerName: "Color", 
      flex: 1, 
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true
    },
    {
      field: "size38",
      headerName: "38",
      flex: 1,
      editable: true,
      type: "number",
      sortable: false,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
    },
    {
      field: "size40",
      headerName: "40",
      flex: 1,
      editable: true,
      type: "number",
      sortable: false,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
    },
];

export const PricingTableColumns = [
    { 
      field: "sku", 
      headerName: "SKUs", 
      flex: 1, 
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true
    },
    {
      field: "range",
      headerName: "Range(Quantity)",
      flex: 1,
      editable: false,
      type: "number",
      sortable: false,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      editable: false,
      type: "number",
      sortable: false,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
    },
];

export const pricingTableRows = [
    { id: 1, sku: "Orange 38, Orange 40,", range: '1 to 15', price: '500₹' },
    { id: 2, sku: "Orange 38, Orange 40,", range: '16 to 50', price: '400₹' },
    { id: 3, sku: "Black 40,,", range: '16 to 50', price: '300₹' },
    { id: 4, sku: "Black 40,,", range: '1 to 15', price: '200₹' },
];