import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

function RenderFooter() {
    const location = useLocation();
    const showFooter = location.pathname === '/'; // Check if the current path is '/'
  
    return showFooter ? <Footer /> : null; // Conditionally render Footer
}

export default RenderFooter;