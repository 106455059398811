import React, { useState, useEffect } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import "./ManageInventory.css";
import { useToast } from '../context/ToastContext';
import { logInventoryChange } from '../utils/inventoryUtils';
import useAdminRole from '../hooks/useAdminRole';

const ManageInventory = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState(null);
  const [newStock, setNewStock] = useState('');
  const toast = useToast();
  const { role, user } = useAdminRole();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const productsSnapshot = await getDocs(collection(db, "parent_products"));
      const productsData = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Failed to load inventory data");
    } finally {
      setLoading(false);
    }
  };

  const openStockModal = (sku, productId, productName) => {
    setSelectedSku({ ...sku, productId, productName });
    setNewStock(sku.stock.toString());
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSku(null);
    setNewStock('');
  };

  const updateStock = async () => {
    if (!selectedSku || newStock === '') return;

    try {
      const productRef = doc(db, 'parent_products', selectedSku.productId);
      const product = products.find(p => p.id === selectedSku.productId);
      
      if (!product) {
        throw new Error('Product not found');
      }

      const updatedSkus = product.skus.map(sku => 
        sku.skuId === selectedSku.skuId 
          ? { ...sku, stock: parseInt(newStock) }
          : sku
      );

      await updateDoc(productRef, { skus: updatedSkus });
      
      const updatedBy = role === 'admin' ? 'admin' : role === 'staff' ? 'staff' : 'unknown';
      
      await logInventoryChange({
        productId: selectedSku.productId,
        productName: selectedSku.productName,
        skuId: selectedSku.skuId,
        previousStock: selectedSku.stock,
        newStock: parseInt(newStock),
        type: 'manual_update',
        updatedBy
      });

      await fetchProducts();
      toast.success("Stock updated successfully");
      closeModal();
    } catch (error) {
      console.error("Error updating stock:", error);
      toast.error("Failed to update stock");
    }
  };

  if (loading) {
    return <div className="loading">Loading inventory...</div>;
  }

  return (
    <div className="manage-inventory">
      <h2>Manage Inventory</h2>

      <table className="inventory-table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>SKU ID</th>
            <th>Color</th>
            <th>Size</th>
            <th>Image</th>
            <th>Current Stock</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            product.skus?.map(sku => (
              <tr key={sku.skuId}>
                <td data-label="Product Name">{product.name}</td>
                <td data-label="SKU ID">{sku.skuId}</td>
                <td data-label="Color">{sku.color}</td>
                <td data-label="Size">{sku.size}</td>
                <td data-label="Image">
                  <img 
                    src={sku.imageUrl} 
                    alt={sku.skuId} 
                    className="sku-thumbnail"
                  />
                </td>
                <td data-label="Current Stock">{sku.stock}</td>
                <td data-label="Actions">
                  <button 
                    onClick={() => openStockModal(sku, product.id, product.name)}
                    className="btn-edit"
                  >
                    Update Stock
                  </button>
                </td>
              </tr>
            ))
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3>Update Stock</h3>
            </div>
            <div className="modal-content">
              <div className="stock-update-form">
                <div className="sku-image">
                  <img 
                    src={selectedSku?.imageUrl} 
                    alt={selectedSku?.skuId} 
                    className="modal-sku-image"
                  />
                </div>
                <div className="sku-info">
                  <p><strong>Product:</strong> {selectedSku?.productName}</p>
                  <p><strong>SKU ID:</strong> {selectedSku?.skuId}</p>
                  <p><strong>Color:</strong> {selectedSku?.color}</p>
                  <p><strong>Size:</strong> {selectedSku?.size}</p>
                  <p><strong>Current Stock:</strong> {selectedSku?.stock}</p>
                </div>
                <div className="form-group">
                  <label>New Stock Quantity</label>
                  <input
                    type="number"
                    value={newStock}
                    onChange={(e) => setNewStock(e.target.value)}
                    min="0"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="modal-actions">
              <button onClick={updateStock} className="btn-save">Save</button>
              <button onClick={closeModal} className="btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageInventory;
