import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomerInformation = ({ userDetails, order }) => {
  const navigate = useNavigate();

  const handleViewCustomerProfile = () => {
    if (order.userId) {
      navigate(`/admin/customer-profile/${order.userId}`);
    }
  };

  return (
    <div className="customer-details card">
      <div className="customer-header">
        <h3>Customer Information</h3>
        {order.userId && (
          <button 
            onClick={handleViewCustomerProfile}
            className="btn-view-profile"
          >
            View Profile
          </button>
        )}
      </div>
      <p><strong>Name:</strong> {userDetails?.billingName || 'NA'}</p>
      <p><strong>Email:</strong> {userDetails?.email}</p>
      <p><strong>Mobile:</strong> {userDetails?.mobileNumber || 'NA'}</p>
      <p><strong>Shipping Address:</strong></p>
      <address>{order.shippingAddress || 'NA'}</address>
    </div>
  );
};

export default CustomerInformation; 