import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastProvider } from './context/ToastContext';
import { HelmetProvider } from 'react-helmet-async';
import AdminRoutes from './routes/AdminRoutes';
import UserRoutes from './routes/UserRoutes';
import Navbar from './components/Navbar/Navbar';
import AdminNavbar from './admin/Adminnavbar';
import useAdminRole from './hooks/useAdminRole';
import Login from './pages/Login/Login';
import Invoice from './admin/Invoice';
import RenderFooter from './components/Footer/RenderFooter';
import FAQ from './pages/FAQ/FAQ';
import RefundPolicy from './pages/Refund/RefundPolicy';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function App() {
  const { role } = useAdminRole();

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <ToastProvider>
          <Router>
            <div>
              {role === 'admin' || role === 'staff' ? <AdminNavbar /> : <Navbar />}
              <Routes>
                {role === 'admin' || role === 'staff' ? 
                  <Route path="/admin/*" element={<AdminRoutes />} /> : 
                  <Route path="/*" element={<UserRoutes />}/>
                }
                <Route path="/login" element={<Login />} key="login" />,
                <Route path="/invoice/:invoiceId" element={<Invoice />} />
                <Route path="/faq" element={<FAQ/>} />
                <Route path="/refund-policy" element={<RefundPolicy/>} />
              </Routes>
              <RenderFooter />
            </div>
          </Router>
        </ToastProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
