import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [],
  totalQuantity: 0,
  totalPrice: 0,
  currentEdit: null,
};

const calculateTotals = (items) => {
  return items.reduce((acc, item) => {
    const itemTotal = item.selections.reduce((selAcc, selection) => {
      const basePrice = parseInt(acc.totalQuantity > 15 ? selection.lowerPrice : selection.basePrice);
      const quantity = selection.quantity;
      const gstAmount = (selection.gst / 100) * basePrice * quantity;
      
      return {
        totalQuantity: selAcc.totalQuantity + quantity,
        totalPrice: selAcc.totalPrice + (basePrice * quantity) + gstAmount
      };
    }, { totalQuantity: 0, totalPrice: 0 });

    return {
      totalQuantity: acc.totalQuantity + itemTotal.totalQuantity,
      totalPrice: acc.totalPrice + itemTotal.totalPrice
    };
  }, { totalQuantity: 0, totalPrice: 0 });
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(item => item.id === newItem.id);
      
      if (existingItem) {
        state.cartItems = state.cartItems.map(item =>
          item.id === newItem.id ? newItem : item
        );
      } else {
        state.cartItems.push(newItem);
      }
      
      const totals = calculateTotals(state.cartItems);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;
    },
    
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(item => item.id !== action.payload);
      const totals = calculateTotals(state.cartItems);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;
    },
    
    setCurrentEdit: (state, action) => {
      state.currentEdit = action.payload;
    },
    
    clearCart: (state) => {
      state.cartItems = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.currentEdit = null;
    }
  }
});

export const { addToCart, removeFromCart, setCurrentEdit, clearCart } = cartSlice.actions;
export default cartSlice.reducer; 