import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase-config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useToast } from '../context/ToastContext';
import ProductInformation from './components/ProductDetails/ProductInformation';
import ProductActions from './components/ProductDetails/ProductActions';
import useAdminRole from '../hooks/useAdminRole';
import './ParentProductDetails.css';

// Initial SKU form state
const initialSkuState = {
  skuId: '',
  color: '',
  size: '',
  stock: 0,
  pricing: [
    { minQuantity: 1, maxQuantity: 15, price: '' },
    { minQuantity: 16, maxQuantity: 50, price: '' },
  ],
  imageUrl: '',
};

const ParentProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [skuForm, setSkuForm] = useState(initialSkuState);
  const [editingSkuId, setEditingSkuId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { role } = useAdminRole();

  const fetchProduct = async () => {
    if (!productId) {
      toast.error('Invalid product ID');
      navigate('/admin/manage-products');
      return;
    }

    try {
      const productRef = doc(db, 'parent_products', productId);
      const productDoc = await getDoc(productRef);

      if (productDoc.exists()) {
        setProduct({
          id: productDoc.id,
          ...productDoc.data()
        });
      } else {
        toast.error('Product not found');
        navigate('/admin/manage-products');
      }
    } catch (error) {
      console.error('Error fetching product:', error);
      toast.error('Error loading product details');
      navigate('/admin/manage-products');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  const handleDelete = async () => {
    if (!product?.id) return;
    
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteDoc(doc(db, 'parent_products', product.id));
        toast.success('Product deleted successfully');
        navigate('/admin/manage-products');
      } catch (error) {
        console.error('Error deleting product:', error);
        toast.error('Failed to delete product');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSkuForm(prev => ({ ...prev, [name]: value }));
  };

  const handlePricingChange = (index, field, value) => {
    const updatedPricing = [...skuForm.pricing];
    updatedPricing[index][field] = value;
    setSkuForm(prev => ({ ...prev, pricing: updatedPricing }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      const timestamp = Date.now();
      const storageRef = ref(storage, `sku_images/${timestamp}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error('Error uploading image:', error);
          setIsUploading(false);
          toast.error('Failed to upload image');
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setSkuForm(prev => ({ ...prev, imageUrl: downloadURL }));
          setIsUploading(false);
          toast.success('Image uploaded successfully');
        }
      );
    }
  };

  const openSkuModal = (sku = null) => {
    if (sku) {
      setSkuForm(sku);
      setEditingSkuId(sku.skuId);
    } else {
      setSkuForm(initialSkuState);
      setEditingSkuId(null);
    }
    setIsModalOpen(true);
  };

  const closeSkuModal = () => {
    setIsModalOpen(false);
    setSkuForm(initialSkuState);
    setEditingSkuId(null);
  };

  const saveSku = async () => {
    if (!product?.id || !skuForm.skuId || !skuForm.size || !skuForm.color || !skuForm.stock) {
      toast.error('Please fill all required fields');
      return;
    }

    try {
      const productRef = doc(db, 'parent_products', product.id);
      const updatedSkus = product.skus || [];
      
      if (editingSkuId) {
        // Update existing SKU
        const skuIndex = updatedSkus.findIndex(sku => sku.skuId === editingSkuId);
        if (skuIndex !== -1) {
          // When editing, preserve the original stock value
          const originalStock = updatedSkus[skuIndex].stock;
          updatedSkus[skuIndex] = {
            ...skuForm,
            stock: originalStock // Keep the original stock value
          };
        }
      } else {
        // Add new SKU
        if (updatedSkus.some(sku => sku.skuId === skuForm.skuId)) {
          toast.error('SKU ID already exists');
          return;
        }
        updatedSkus.push(skuForm);
      }

      await updateDoc(productRef, { skus: updatedSkus });
      await fetchProduct(); // Refresh product data
      toast.success(`SKU ${editingSkuId ? 'updated' : 'added'} successfully`);
      closeSkuModal();
    } catch (error) {
      console.error('Error saving SKU:', error);
      toast.error('Failed to save SKU');
    }
  };

  const deleteSku = async (skuId) => {
    if (window.confirm('Are you sure you want to delete this SKU?')) {
      try {
        const productRef = doc(db, 'parent_products', product.id);
        const updatedSkus = product.skus.filter(sku => sku.skuId !== skuId);
        await updateDoc(productRef, { skus: updatedSkus });
        await fetchProduct(); // Refresh product data
        toast.success('SKU deleted successfully');
      } catch (error) {
        console.error('Error deleting SKU:', error);
        toast.error('Failed to delete SKU');
      }
    }
  };

  if (loading) {
    return <div className="loading">Loading product details...</div>;
  }

  if (!product) {
    return <div className="error">Product not found</div>;
  }

  return (
    <div className="product-details-container">
      <h2>Product Details</h2>
      <div className="product-details-grid">
        <ProductInformation product={product} productId={productId} />
        {role === 'admin' && (
          <ProductActions productId={productId} onDelete={handleDelete} />
        )}
      </div>

      <div className="sku-section">
        <div className="sku-header">
          <h3>SKUs</h3>
          <button onClick={() => openSkuModal()} className="btn-add">Add New SKU</button>
        </div>

        <table className="sku-table">
          <thead>
            <tr>
              <th>SKU ID</th>
              <th>Color</th>
              <th>Size</th>
              <th>Stock</th>
              <th>Image</th>
              <th>Pricing</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {product.skus?.map((sku) => (
              <tr key={sku.skuId}>
                <td>{sku.skuId}</td>
                <td>{sku.color}</td>
                <td>{sku.size}</td>
                <td>{sku.stock}</td>
                <td>
                  <img src={sku.imageUrl} alt={sku.skuId} className="sku-thumbnail" />
                </td>
                <td>
                  <ul className="pricing-list">
                    {sku.pricing.map((price, index) => (
                      <li key={index}>
                        {price.minQuantity}-{price.maxQuantity} pcs: ₹{price.price}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <button onClick={() => openSkuModal(sku)} className="btn-edit">Edit</button>
                  {role === 'admin' && (
                    <button onClick={() => deleteSku(sku.skuId)} className="btn-delete">Delete</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3>{editingSkuId ? 'Edit SKU' : 'Add New SKU'}</h3>
            </div>
            <div className="modal-content">
              <div className="form-group">
                <label>SKU ID</label>
                <input
                  type="text"
                  name="skuId"
                  value={skuForm.skuId}
                  onChange={handleInputChange}
                  disabled={!!editingSkuId}
                  required
                />
              </div>

              <div className="form-group">
                <label>Color</label>
                <input
                  type="text"
                  name="color"
                  value={skuForm.color}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Size</label>
                <input
                  type="text"
                  name="size"
                  value={skuForm.size}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Stock</label>
                <input
                  type="number"
                  name="stock"
                  value={skuForm.stock}
                  onChange={handleInputChange}
                  required
                  disabled={!!editingSkuId}
                  title={editingSkuId ? "Stock can only be updated from Manage Inventory" : ""}
                />
                {editingSkuId && (
                  <small className="help-text">
                    Stock can only be updated from Manage Inventory to maintain proper tracking
                  </small>
                )}
              </div>

              <div className="form-section">
                <h4>Pricing Details</h4>
                {skuForm.pricing.map((pricing, index) => (
                  <div key={index} className="pricing-entry">
                    <label>Price Range {index + 1}</label>
                    <div className="pricing-inputs">
                      <input
                        type="number"
                        value={pricing.minQuantity}
                        onChange={(e) => handlePricingChange(index, "minQuantity", e.target.value)}
                        placeholder="Min Quantity"
                      />
                      <input
                        type="number"
                        value={pricing.maxQuantity}
                        onChange={(e) => handlePricingChange(index, "maxQuantity", e.target.value)}
                        placeholder="Max Quantity"
                      />
                      <input
                        type="number"
                        value={pricing.price}
                        onChange={(e) => handlePricingChange(index, "price", e.target.value)}
                        placeholder="Price"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="form-group">
                <label>Upload Image</label>
                <input type="file" onChange={handleImageChange} accept="image/*" />
                {skuForm.imageUrl && (
                  <img src={skuForm.imageUrl} alt="SKU" className="image-preview" />
                )}
              </div>
            </div>

            <div className="modal-actions">
              <button onClick={saveSku} disabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Save'}
              </button>
              <button onClick={closeSkuModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ParentProductDetails; 