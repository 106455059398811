export const calculateItemPrice = (selection, totalQuantity) => {
  const isLowerPriceApplied = totalQuantity > 15;
  const basePrice = parseInt(isLowerPriceApplied ? selection.lowerPrice : selection.basePrice);
  const gstAmount = (selection.gst / 100) * basePrice;
  
  return {
    basePrice,
    gstAmount,
    total: (basePrice + gstAmount) * selection.quantity,
    isLowerPriceApplied
  };
};

export const calculateCartTotals = (cartItems) => {
  const totalQuantity = cartItems.reduce((total, item) => {
    return total + item.selections.reduce((sum, sel) => sum + sel.quantity, 0);
  }, 0);

  let subtotal = 0;

  let grandTotal = 0;

  cartItems.forEach(item => {
    item.selections.forEach(selection => {
      const { basePrice, total } = calculateItemPrice(selection, totalQuantity);
      subtotal += basePrice * selection.quantity;
   
      grandTotal += total;
    });
  });

  return { subtotal,  grandTotal, totalQuantity };
}; 