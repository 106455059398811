import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Grid2,
  styled,
} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export const ImageDiv = styled('img')(({ theme }) => ({
    width: '100%',
    height: '500px',
    // Responsive display based on breakpoints
    [theme.breakpoints.down('md')]: {
        height: '380px', // Hide on small screens
    },
}));

const ImageGallery = ({ imagesList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [thumbTranslateX, setThumbTranslateX] = useState(0);
  const visibleThumbnails = 2; // Number of thumbnails visible at once

  // Ref for the thumbnail container
  const thumbnailsRef = useRef(null);

  // Ref for each thumbnail
  const thumbnailRefs = useRef([]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? imagesList?.img.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === imagesList?.img.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const shiftValue = Math.floor(currentIndex / visibleThumbnails) * visibleThumbnails;
    setThumbTranslateX(shiftValue);

    // Ensure the current thumbnail scrolls into view
    if (thumbnailRefs.current[currentIndex]) {
      thumbnailRefs.current[currentIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [currentIndex]);

  return (
      <Box>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transition: 'transform 0.5s ease-in-out',
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {imagesList?.img.map((image, index) => (
              <Box
                key={image.key}
                sx={{ minWidth: '100%', boxSizing: 'border-box' }}
              >
                <ImageDiv
                  src={`${image.img}`}
                  alt={`${image.alt}`}
                />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Thumbnail Section */}
        <Grid2 container spacing={1} sx={{ mt: 4 }}>
          <Box
            ref={thumbnailsRef}
            sx={{
              display: 'flex',
              backgroundColor: '#80808012',
              overflowX: 'auto', // Enable horizontal scrollbar
              width: '100%',
              scrollbarWidth: 'thin', // For Firefox
              '&::-webkit-scrollbar': {
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}
          >
            {imagesList?.img.map((image, index) => (
              <Grid2 item key={index}>
                <Box
                  ref={(el) => (thumbnailRefs.current[index] = el)}
                //   border={index === currentIndex ? '1px solid #33387C' : ''}
                  onClick={() => setCurrentIndex(index)}
                  sx={{
                    cursor: 'pointer',
                    margin: '0 2px',
                    transition: 'transform 0.5s ease',
                    height: '102px',
                    border: index === currentIndex ? '1px solid #33387C': '',
                  }}
                >
                  <img
                    id={image.key}
                    src={`${image.img}`}
                    alt={`${image.key}`}
                    style={{ width: 90, height: 98, objectFit: 'contain'}}
                  />
                </Box>
              </Grid2>
            ))}
          </Box>
        </Grid2>

        <Grid2 container spacing={1} justifyContent='center' sx={{ mt: 2 }}>
          <IconButton onClick={handlePrev} disabled={currentIndex + 1 === 1}>
            <ArrowCircleLeftIcon />
          </IconButton>
          <Typography
            variant='body1'
            align='center'
            style={{ margin: '10px 0' }}
          >
            {currentIndex + 1}
            {' '}
            /
            {imagesList?.img.length}
          </Typography>
          <IconButton
            onClick={handleNext}
            disabled={currentIndex + 1 === imagesList?.img.length}
          >
            <ArrowCircleRightIcon />
          </IconButton>
        </Grid2>
      </Box>
  );
};

export default ImageGallery;