import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';
import './AdminNavbar.css';
import useAdminRole from '../hooks/useAdminRole'; // Import hook to check permissions
import { AppBar, Toolbar, Button, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material'; // Import Material-UI components
import MenuIcon from '@mui/icons-material/Menu'; // Import MenuIcon for hamburger menu

const AdminNavbar = () => {
  const navigate = useNavigate();
  const { permissions } = useAdminRole(); // Get permissions from the hook
  const [anchorElNav, setAnchorElNav] = React.useState(null); // State for mobile menu

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
      alert('Failed to log out');
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(null);
    navigate(path);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography variant="h6" onClick={() => navigate("/admin")} sx={{ cursor: 'pointer' }}>
          Admin Dashboard
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
          sx={{ display: { xs: 'flex', md: 'none' } }} // Show only on mobile
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={() => setAnchorElNav(null)}
        >
          {permissions.includes("manage_users") && (
            <MenuItem onClick={() => handleCloseNavMenu("/admin/manage-users")}>Users</MenuItem>
          )}
          {permissions.includes("manage_products") && (
            <MenuItem onClick={() => handleCloseNavMenu("/admin/manage-products")}>Products</MenuItem>
          )}
          {permissions.includes("manage_orders") && (
            <MenuItem onClick={() => handleCloseNavMenu("/admin/manage-orders")}>Orders</MenuItem>
          )}
          {permissions.includes("manage_inventory") && (
            <MenuItem onClick={() => handleCloseNavMenu("/admin/manage-inventory")}>Inventory</MenuItem>
          )}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {permissions.includes("manage_users") && (
            <Button color="inherit" onClick={() => navigate("/admin/manage-users")}>Users</Button>
          )}
          {permissions.includes("manage_products") && (
            <Button color="inherit" onClick={() => navigate("/admin/manage-products")}>Products</Button>
          )}
          {permissions.includes("manage_orders") && (
            <Button color="inherit" onClick={() => navigate("/admin/manage-orders")}>Orders</Button>
          )}
          {permissions.includes("manage_inventory") && (
            <Button color="inherit" onClick={() => navigate("/admin/manage-inventory")}>Inventory</Button>
          )}
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AdminNavbar;
