import { Box, Grid2, Typography, Paper, Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import {  PricingTableColumns } from '../Product/utils';
import EditableTable from '../../components/EditableTable/EditableTable';
import { useProductDetails } from '../Product/hooks'
import { useParams } from 'react-router-dom';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import { Helmet } from 'react-helmet-async';

const ProductDetails = () => {
    const { slug } = useParams();
    const { 
      skuRows, 
      setSkuRows, 
      skuTableColumns, 
      loading, 
      skus, 
      parentProduct, 
      pricingTableRows, 
      imageList, 
      handleAddToCart, 
      setSelectedQuantities,
      currentEdit
    } = useProductDetails(slug);
    const loader = () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
    const getMetaDescription = (product) => {
      if (!product) return '';
      return `${product.name} - ${product.description.substring(0, 155)}...`;
    };
    
    return(
      <>
        {parentProduct && (
        <Helmet>
          <title>{`${parentProduct.name} | Your Store Name`}</title>
          <meta name="description" content={getMetaDescription(parentProduct)} />
          <meta property="og:title" content={parentProduct.name} />
          <meta property="og:description" content={getMetaDescription(parentProduct)} />
          <meta property="og:image" content={parentProduct.imageUrl} />
          <meta property="og:url" content={window.location.href} />
          <link rel="canonical" href={window.location.href} />
          
          {/* Wrap the JSON-LD in a template literal */}
          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${parentProduct.name}",
              "description": "${parentProduct.description}",
              "image": "${parentProduct.imageUrl}",
              "offers": {
                "@type": "AggregateOffer",
                "priceCurrency": "INR",
                "lowPrice": ${Math.min(...parentProduct.skus.flatMap(sku => 
                  sku.pricing.map(p => p.price)))},
                "highPrice": ${Math.max(...parentProduct.skus.flatMap(sku => 
                  sku.pricing.map(p => p.price)))},
                "availability": "${parentProduct.skus.some(sku => sku.stock > 0) 
                  ? "https://schema.org/InStock" 
                  : "https://schema.org/OutOfStock"}"
              }
            }
          `}</script>
        </Helmet>
      )}
      <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', minHeight: '100vh', mb: {xs: 2, md: 4 } }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Paper sx={{ padding: 2, elevation: 0 }}>
              {!loading && imageList && imageList?.img?.length > 0 ? <ImageGallery imagesList={imageList}/>: loader()}
            </Paper>
          </Grid2>

          <Grid2 size={{ xs: 12, md: 8 }}>
            <Paper sx={{ padding: 2, elevation: 0 }}>
              <Typography variant="h5" sx={{ color: "#33387C" }} fontWeight="bold">{parentProduct?.name}</Typography>
              <Typography sx={{ mt: 1, fontSize: '14px' }}>{parentProduct?.description}</Typography>
              <Typography sx={{ mt: 1, fontSize: '14px' }}>GST: {parentProduct?.gst}%</Typography>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, color: "#33387C" }}>
                Select SKUs
              </Typography>
              <Box sx={{ marginTop: '10px'}}>
                <EditableTable
                  rows={skuRows}
                  columns={skuTableColumns?.length ? skuTableColumns : []}
                  setSkuRows={setSkuRows}
                  hideFooter={true}
                  loading={loading}
                  setSelectedQuantities={setSelectedQuantities}
                />
              </Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, color: "#33387C" }}>
                Pricing Information
              </Typography>
              <Box sx={{ marginTop: '10px'}}>
                <EditableTable
                  rows={pricingTableRows}
                  columns={PricingTableColumns}
                  hideFooter={true}
                  loading={loading}
                />
              </Box>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end'}} >
                <Button variant="contained" sx={{ width: {xs: '100%', md: '15%'}, backgroundColor: '#33387C'}} type='submit' onClick={handleAddToCart}>
                  {currentEdit ? 'Update cart': 'Add to cart'}
                </Button>
              </Box>
            </Paper>
          </Grid2>
        </Grid2>
    </Box>
    </>
    )
}

export default ProductDetails;