import React from 'react';
import { Container, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ() {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Typography gutterBottom sx={{ textAlign: 'center', mb: 3, fontWeight: 'bold', fontSize: {xs: '20px', md: '32px'}, color: '#33387C' }}>
        Frequently Asked Questions
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>Is there a minimum quantity required for a custom product?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Bulk Dukaan has no Minimum Order Quantity. You can customize and order single-piece T-shirts, Hoodies, Polo
            T-shirts, Mugs, and other customizable products.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>Can I get any bulk order discount if I place a bulk order?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you order anything above 15 pieces, Bulk Dukaan gives you an extra discount. You can also get in touch
            with us at:
          </Typography>
          <Typography>Email: your-email@example.com</Typography>
          <Typography>Mob: your-phone-number</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>How long does it take to receive my order?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Two types of products are provided:
            <ul>
              <li>
                <strong>Plain (without printing):</strong> Same-day dispatch is available.
              </li>
              <li>
                <strong>Customized (with printing):</strong> May take 2 to 7 working days depending on the order
                quantity, product color, and print design.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>How do I pay for my order?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer the following payment options:
            <ul>
              <li>Credit Card</li>
              <li>Debit Card</li>
              <li>Net Banking</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>How do I check the status of my order?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can call or WhatsApp us at:
          </Typography>
          <Typography>Email: trendydice@gmail.com</Typography>
          <Typography>Mob: 97380-97381</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>What if the product is defective?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We ensure all our products are thoroughly checked before being sent out. However, if there are any defects
            due to unforeseeable circumstances, we will replace them.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>How do I order customized products?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>You can order through Call, Message, or Email.</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>How do I order bulk plain products?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>You can order through the website.</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>How do I check the serviceable area?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>We deliver Pan India.</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: 'bold' }}>Which type of printing is provided?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We provide the following types of printing:
            <ul>
              <li>DTF Printing</li>
              <li>DTG Printing</li>
              <li>Screen Printing</li>
              <li>Embroidery</li>
              <li>Puff Printing</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default FAQ;
