import React from 'react';
import { ORDER_STATUS_OPTIONS } from '../../utils/orderUtils';

const OrderActions = ({ 
  newStatus, 
  setNewStatus, 
  handleStatusChange, 
  assignedStaff, 
  setAssignedStaff, 
  handleAssignStaff, 
  staffList,
  showShippingInput,
  shippingLink,
  setShippingLink,
  handleShippingSubmit 
}) => {
  return (
    <>
      <div className="status-section card">
        <div className="status-update-container">
          <h5>Update Order Status</h5>
          <div className="status-controls">
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              className="status-dropdown"
            >
              {ORDER_STATUS_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <button onClick={handleStatusChange} className="btn-update-status">
              Update Status
            </button>
          </div>
        </div>

        <div className="staff-assignment-container">
          <h5>Assign Staff Member</h5>
          <div className="status-controls">
            <select
              value={assignedStaff}
              onChange={(e) => setAssignedStaff(e.target.value)}
              className="status-dropdown"
            >
              <option value="">Select a staff member</option>
              {staffList.map(staff => (
                <option key={staff.id} value={staff.email}>{staff.email}</option>
              ))}
            </select>
            <button onClick={handleAssignStaff} className="btn-update-status">
              Assign Staff
            </button>
          </div>
        </div>
      </div>

      {showShippingInput && (
        <div className="shipping-section card">
          <h3>Enter Shipping ID/Link</h3>
          <input
            type="text"
            value={shippingLink}
            onChange={(e) => setShippingLink(e.target.value)}
            placeholder="Enter shipping ID or tracking link"
            className="shipping-input"
          />
          <button onClick={handleShippingSubmit} className="btn-submit-shipping">
            Submit
          </button>
        </div>
      )}
    </>
  );
};

export default OrderActions; 