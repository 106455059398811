// Order status constants
export const ORDER_STATUSES = {
  PENDING: 'Pending',
  PACKED: 'Packed',
  SHIPPED: 'Shipped',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled'
};

// Order status options for dropdowns
export const ORDER_STATUS_OPTIONS = [
  { value: ORDER_STATUSES.PENDING, label: 'Pending' },
  { value: ORDER_STATUSES.PACKED, label: 'Packed' },
  { value: ORDER_STATUSES.SHIPPED, label: 'Shipped' },
  { value: ORDER_STATUSES.DELIVERED, label: 'Delivered' },
  { value: ORDER_STATUSES.CANCELLED, label: 'Cancelled' }
];

// Helper function to determine if shipping input should be shown
export const shouldShowShippingInput = (status) => {
  return status === ORDER_STATUSES.SHIPPED;
}; 